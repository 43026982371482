import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getCookie, getDraftProxyApplication } from '../../../../utils/utility';
import { updateUserDetails } from '../BasicSettings/basicSettingsSlice';

export const deleteUserDetails = createAsyncThunk(
  'api/deleteUserDetails',
  async (
    {
      commonID,
      tenantID,
      app,
      username,
      macAddress,
    }: {
      commonID: string;
      tenantID: string;
      app: string;
      username: string;
      macAddress: string;
    },
    thunkAPI,
  ) => {
    try {
      const token = await getCookie('token');
      const state: any = thunkAPI.getState();
      const payloadData = {
        _id: commonID,
        tenant_id: tenantID,
        appName: app,
        username,
        macAddress,
      };
      await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/casb/delete/device-approval`,
        payloadData,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      const response: any = await getDraftProxyApplication(
        state.application.app,
      );
      const updatedUserDetails = response.success.device_management.user_details;
      await thunkAPI.dispatch(
        updateUserDetails(response.success.device_management.user_details),
      );
      return updatedUserDetails;
    } catch (error) {
      toast.error('Unable to send the delete request.');
      return thunkAPI.rejectWithValue('Failed to delete user details');
    }
  },
);
export const handleDeviceAction = createAsyncThunk(
  'api/handleDeviceAction',
  async (
    {
      commonID,
      tenantID,
      app,
      username,
      access,
      macAddress,
      permanent_access: permanentAccess,
      successMessage,
    }: {
      commonID: string;
      tenantID: string;
      app: string;
      username: string;
      macAddress: string;
      access: boolean;
      permanent_access: boolean;
      successMessage: string;
    },
    thunkAPI,
  ) => {
    try {
      const token = await getCookie('token');
      const state: any = thunkAPI.getState();
      const payloadData = {
        _id: commonID,
        tenant_id: tenantID,
        appName: app,
        username,
        macAddress,
        access,
        approval_data: {
          permanent_access: permanentAccess,
          custom: {
            duration: permanentAccess ? 0 : undefined,
            type: permanentAccess ? '' : undefined,
          },
        },
      };
      await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/casb/device-action`,
        payloadData,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      toast.success(successMessage);
      const response: any = await getDraftProxyApplication(
        state.application.app,
      );
      const updatedUserDetails = response.success.device_management.user_details;
      await thunkAPI.dispatch(
        updateUserDetails(response.success.device_management.user_details),
      );
      return updatedUserDetails;
    } catch (error) {
      toast.error('Unable to send the request.');
      return thunkAPI.rejectWithValue('Failed to handle device action');
    }
  },
);
