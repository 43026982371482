import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRestrictionPerDay } from './preferencesHandler';

// interface FilterRow {
//   id: number;
//   fromDate?: string;
//   toDate?: string;
//   selectedField: string;
//   selectedMultipleFields?: string[];
//   chipList: string[];
// }

interface PreferencesState {
  status: 'idle' | 'loading' | 'succeeded';
  isAuditingDashboard: boolean;
  isDarkTheme: string;
  userEmail: string;
  severityDate: string;
  severityInsights: {};
  restrictedUsers: any[];
  startTime: string;
  endTime: string;
  emailAddresses: any[];
  ipAddresses: any[];
  fields: any[];
  groupBy: any[];
  statusArray: any[];
  appAccessed: any[];
  casbApp: any[];
  groupIds: any[];
  macAddresses: any[];
  limit: number;
  page: number;
  totalRestrictions: any[];
  totalUsers: any[];
  showFilterBox: boolean;
  selectedField: string;
  chipList: string[];
  filterRows: any[];
  selectedDateButton: number;
  apiType: string;
  isBreadcrumbOpen: boolean;
  pinnedApps: { [key: string]: string[] };
  pinnedAppln: Record<string, string[]>;
}

const initialState: PreferencesState = {
  status: 'idle',
  isAuditingDashboard: false,
  isDarkTheme: 'light',
  userEmail: '',
  severityDate: '',
  severityInsights: [],
  restrictedUsers: [],
  startTime: '',
  endTime: '',
  emailAddresses: [],
  ipAddresses: [],
  fields: [],
  groupBy: [],
  statusArray: [],
  appAccessed: [],
  casbApp: [],
  groupIds: [],
  macAddresses: [],
  limit: 10,
  page: 1,
  totalRestrictions: [],
  totalUsers: [],
  showFilterBox: true,
  selectedField: '',
  chipList: [],
  selectedDateButton: -1,
  filterRows: [{ id: Date.now() }],
  apiType: 'fields',
  isBreadcrumbOpen: false,
  pinnedApps: {},
  pinnedAppln: {},
};

const preferences = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    updateSeverityDate: (state, action: PayloadAction<string>) => {
      state.severityDate = action.payload;
    },
    updateDarkTheme: (state, action: PayloadAction<string>) => {
      state.isDarkTheme = action.payload;
    },
    updateEnableAuditingDashboard: (state, action: PayloadAction<boolean>) => {
      state.isAuditingDashboard = action.payload;
    },
    resetAuditingDashboard: (state) => {
      state.isAuditingDashboard = false;
    },
    updateUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    resetUserEmail: (state) => {
      state.userEmail = '';
    },
    updateEmailAddresses: (state, action: PayloadAction<string[]>) => {
      state.emailAddresses = action.payload;
    },
    resetEmailAddresses: (state) => {
      state.emailAddresses = [];
    },
    updateIPAddresses: (state, action: PayloadAction<string[]>) => {
      state.ipAddresses = action.payload;
    },
    resetIPAddresses: (state) => {
      state.ipAddresses = [];
    },
    updateGroupBy: (state, action: PayloadAction<string[]>) => {
      state.groupBy = action.payload;
    },
    resetGroupBy: (state, action: PayloadAction<string[]>) => {
      state.groupBy = action.payload;
    },
    updateStatusArray: (state, action: PayloadAction<string[]>) => {
      state.statusArray = action.payload;
    },
    resetStatusArray: (state) => {
      state.statusArray = [];
    },
    updateAppAccesses: (state, action: PayloadAction<string[]>) => {
      state.appAccessed = action.payload;
    },
    resetAppAccesses: (state) => {
      state.appAccessed = [];
    },
    updateCasbApp: (state, action: PayloadAction<string[]>) => {
      state.casbApp = action.payload;
    },
    resetCasbApp: (state) => {
      state.casbApp = [];
    },
    updateGroupIds: (state, action: PayloadAction<string[]>) => {
      state.groupIds = action.payload;
    },
    resetGroupIds: (state) => {
      state.groupIds = [];
    },
    updateMacAddresses: (state, action: PayloadAction<string[]>) => {
      state.macAddresses = action.payload;
    },
    resetMacAddresses: (state) => {
      state.macAddresses = [];
    },
    updateLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload - 1;
    },
    resetPage: (state) => {
      state.page = 1;
    },
    updateStartTime: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    resetStartTime: (state) => {
      state.startTime = '';
    },
    resetEndTime: (state) => {
      state.endTime = '';
    },
    updateEndTime: (state, action: PayloadAction<string>) => {
      state.endTime = action.payload;
    },
    updateFilterBox: (state, action: PayloadAction<boolean>) => {
      state.showFilterBox = action.payload;
    },
    updateSelectedDateButton: (state, action: PayloadAction<number>) => {
      state.selectedDateButton = action.payload;
    },
    resetSelectedDateButton: (state) => {
      state.selectedDateButton = -1;
    },
    updateSelectedField: (state, action: PayloadAction<string>) => {
      state.selectedField = action.payload;
    },
    updateChipList: (state, action: PayloadAction<string[]>) => {
      state.chipList = action.payload;
    },
    setApiType: (state, action: PayloadAction<string>) => {
      state.apiType = action.payload;
    },
    setFilterRows: (state, action: PayloadAction<any[]>) => {
      state.filterRows = Array.isArray(action.payload)
        ? action.payload
        : [{ id: Date.now() }];
    },
    setBreadcrumbOpen: (state, action: PayloadAction<boolean>) => {
      state.isBreadcrumbOpen = action.payload;
    },
    saveKeyValue: (
      state,
      action: PayloadAction<Array<{ key: string; values: string[] }>>,
    ) => {
      const keyValuePairs = action.payload;

      if (Array.isArray(keyValuePairs)) {
        keyValuePairs.forEach(({ key, values }) => {
          if (!state.pinnedAppln[key]) {
            state.pinnedAppln[key] = [];
          }

          values.forEach((value) => {
            const existingIndex = state.pinnedAppln[key].indexOf(value);

            if (existingIndex !== -1) {
              state.pinnedAppln[key] = state.pinnedAppln[key].filter(
                (item) => item !== value,
              );
            } else {
              state.pinnedAppln[key].push(value);
            }
          });
        });
      } else {
        console.error('Payload is not an array');
      }
    },

    clearKeyValue: (state) => {
      state.pinnedAppln = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRestrictionPerDay.fulfilled, (state, action) => {
      state.totalUsers = action.payload;
      state.status = 'succeeded';
    });
  },
});

export const {
  updateEnableAuditingDashboard,
  updateUserEmail,
  updateSelectedField,
  updateChipList,
  resetUserEmail,
  updateSeverityDate,
  updateEmailAddresses,
  updateAppAccesses,
  updateCasbApp,
  updateGroupBy,
  updateGroupIds,
  updateIPAddresses,
  updateLimit,
  updateMacAddresses,
  updatePage,
  updateStatusArray,
  resetAppAccesses,
  resetAuditingDashboard,
  resetEmailAddresses,
  resetCasbApp,
  resetIPAddresses,
  resetGroupIds,
  resetMacAddresses,
  resetStatusArray,
  resetGroupBy,
  updateStartTime,
  updateEndTime,
  updateFilterBox,
  resetPage,
  setFilterRows,
  updateSelectedDateButton,
  updateDarkTheme,
  setApiType,
  setBreadcrumbOpen,
  resetStartTime,
  resetEndTime,
  resetSelectedDateButton,
  saveKeyValue,
  clearKeyValue,
} = preferences.actions;
export default preferences.reducer;
