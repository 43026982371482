import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import appSlice from './Slices/Application/appSlice';
import authenticationSourceSlice from './Slices/Application/AuthenticationSource/authenticationSourceSlice';
import policySettings from './Slices/Policy/policy';
import proxySettings from './Slices/Policy/Proxy/proxy';
import basicSettingsSlice from './Slices/Application/BasicSettings/basicSettingsSlice';
import manageGroupSlice from './Slices/Application/AppConfiguration/Group/group';
import sessionManagementSlice from './Slices/Application/SessionManagement/sessionManagement';
import customerSlice from './Slices/Customer/customer';
import extensionFeedbackForm from './Slices/Extension-Feedback/feedback-form';
import customerSupportForm from './Slices/Support/support';
import auditingDashboard from './Slices/Auditing/auditing';
import preferences from './Slices/Auditing/PersistState/preferences';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // whitelist: ['preferences'],
};
const persistedPreferencesReducer = persistReducer(
  persistConfig,
  preferences,
);

export const store = configureStore({
  reducer: {
    application: appSlice.reducer,
    authenticationSource: authenticationSourceSlice.reducer,
    policySettings: policySettings.reducer,
    proxySettings: proxySettings.reducer,
    basicSettings: basicSettingsSlice.reducer,
    groupSettings: manageGroupSlice.reducer,
    session: sessionManagementSlice.reducer,
    customer: customerSlice.reducer,
    extensionFeedback: extensionFeedbackForm.reducer,
    customerSupport: customerSupportForm.reducer,
    auditingDashboard: auditingDashboard.reducer,
    preferences: persistedPreferencesReducer,
  },
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
