import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LoginForm from './pages/Login/LoginForm';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import SignupForm from './pages/Signup/SignupForm';
import './index.css';
import Dashboard from './pages/Dashboard/Dashboard';
import AuthenticationSource from './pages/AuthenticationSource/AuthenticationSource';
import ProxyTable from './pages/Proxy/index';
import AddProxy from './pages/Proxy/Components/AddProxy';
import AddTenantView from './pages/Tenant/Components/AddTenantView';
import PolicyView from './pages/PolicyView/index';
import TenantSettings from './pages/TenantSettings/index';
import PublicLayout from './Router/PublicLayout';
import ProtectedLayout from './Router/ProtectedLayout';
import { apps } from './pages/Application/app';
import './assets/css/app.css';
import Applications from './pages/ApplicationsScreen/applications';
import FeedbackForm from './pages/ExtensionFeedback/feedbackForm';
import AuditingDashboard from './pages/AuditingDashboard/AuditingDashboard';
import UserInsights from './pages/UserInsights/userInsights';
import AppInsights from './pages/ApplicationInsights/appInsights';
// import { UserInsights } from './pages/UserInsights/auditingInsights';
// import { AppInsights } from './pages/AuditingReports/AppInsights';

const publicRoutes = [
  {
    path: '/login',
    element: <LoginForm />,
  },
  {
    path: '/',
    element: <LoginForm />,
  },
  {
    path: '/getting-started',
    element: <SignupForm />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
];
const protectedRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/auditing-dashboard',
    element: <AuditingDashboard />,
  },
  {
    path: '/configure/authentication-source',
    element: <AuthenticationSource />,
  },
  {
    path: '/configure/proxy',
    element: <ProxyTable />,
  },
  {
    path: '/add/proxy',
    element: <AddProxy />,
  },
  {
    path: '/edit/proxy',
    element: <AddProxy />,
  },
  {
    path: '/manage-policy',
    element: <PolicyView />,
  },
  {
    path: '/add/tenant',
    element: <AddTenantView />,
  },
  {
    path: '/settings',
    element: <TenantSettings />,
  },
  {
    path: '/applications',
    element: <Applications />,
  },
  {
    path: '/insights',
    element: <UserInsights />,
  },
  {
    path: '/insights/user/',
    element: <UserInsights />,
  },
  {
    path: '/reports',
    element: <AppInsights />,
  },
];

Object.values(apps).forEach((app) => {
  protectedRoutes.push({
    path: app.path,
    element: app.mainComponent,
  });
});

const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    children: publicRoutes,
  },
  {
    element: <ProtectedLayout />,
    children: protectedRoutes,
  },
  {
    path: '/casb-extension/feedback',
    element: <FeedbackForm />,
  },
]);

export default router;
