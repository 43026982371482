import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCookie } from '../../../utils/utility';

export const getSeverityInsights = createAsyncThunk(
  'getSeverityInsights',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-severity-insights`,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const getRestrictions = createAsyncThunk(
  'getRestrictions',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-restriction-count-per-day`,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.restriction_per_day;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

// show this in table
export const getMostRestrictedUsersData = createAsyncThunk(
  'getMostRestrictedUsersData',
  async (_, thunkAPI) => {
    const token = await getCookie('token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-most-restricted-users`,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.data;
    } catch (err: any) {
      console.error('Error in getMostRestrictedUsersData:', err);
      if (err.response && err.response.data && err.response.data.error) {
        return thunkAPI.rejectWithValue(err.response.data.error);
      }
      throw err;
    }
  },
);
