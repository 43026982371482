/*eslint-disable*/
import {
  removeApp,
  updateAppBlockedList,
  updateAppRestrictionList,
  updateAppAllowedList,
  updateCustomRestrictionList,
  updateBulkCustomRestrictionPolicy,
  updateCustomRestrictionPolicy,
} from '../../Redux/Slices/Application/AppConfiguration/Group/group';

export const determineRestrictionValue = (
  selectedApplications: string[],
  restrictedApps: string[],
  allowedApps: string[],
  blockApps: string[],
  customRestrictionApps: Record<string, string>,
) => {
  if (selectedApplications.length === 0) {
    return '';
  }

  if (selectedApplications.length === 1) {
    const app = selectedApplications[0];
    if (restrictedApps?.includes(app)) {
      return 'AppRestriction';
    }
    if (allowedApps?.includes(app)) return 'NoAppRestricition';
    if (blockApps?.includes(app)) return 'DisableApp';
    if (customRestrictionApps && app in customRestrictionApps) {
      return 'CustomRestriction';
    }
    return 'AppRestriction';
  }

  if (selectedApplications.length > 1) {
    if (
      selectedApplications.every((app: string) => restrictedApps?.includes(app))
    ) return 'AppRestriction';
    if (selectedApplications.every((app: string) => allowedApps?.includes(app))) return 'NoAppRestricition';
    if (selectedApplications.every((app: string) => blockApps?.includes(app))) return 'DisableApp';
    if (
      selectedApplications.every((app: string) => app in customRestrictionApps)
    ) {
      return 'CustomRestriction';
    }
    return 'AppRestriction';
  }
  return 'AppRestriction';
};

export const handleAppRestrictionChange = async (
  selectedApplications: string[],
  dispatch: any,
  value: string,
) => {
  selectedApplications.map(async (appName: any) => {
    await dispatch(removeApp(appName));
    switch (value) {
      case 'AppRestriction':
        await dispatch(updateAppRestrictionList(appName));
        break;
      case 'NoAppRestricition':
        await dispatch(updateAppAllowedList(appName));
        break;
      case 'DisableApp':
        await dispatch(updateAppBlockedList(appName));
        break;
      case 'CustomRestriction':
        await dispatch(updateCustomRestrictionList(appName));
        break;
      default:
        break;
    }
  });
};

export const determineAppPolicyID = (
  appKey: string,
  customRestrictionApps: Record<string, string>,
  defaultPolicyID: string,
) => {
  const app = appKey;
  if (customRestrictionApps && app in customRestrictionApps) {
    return customRestrictionApps[app];
  }
  return defaultPolicyID;
};

export const determinePolicyID = (
  selectedApplications: string[],
  customRestrictionApps: Record<string, string>,
  defaultPolicyID: string,
) => {
  if (selectedApplications.length === 1) {
    const app = selectedApplications[0];
    if (customRestrictionApps && app in customRestrictionApps) {
      return customRestrictionApps[app];
    }
  }

  if (selectedApplications.length > 1) {
    const policyIDs = new Set<string>();

    selectedApplications.forEach((app) => {
      if (customRestrictionApps && app in customRestrictionApps) {
        policyIDs.add(customRestrictionApps[app]);
      }
    });

    if (policyIDs.size === 1) {
      return Array.from(policyIDs)[0];
    }
  }

  return defaultPolicyID;
};

export const handleAppCustomRestrictionPolicyChange = (
  appKey: string,
  value: string,
  dispatch: any,
) => {
  const app = appKey;
  dispatch(
    updateCustomRestrictionPolicy({
      key: app,
      value,
    }),
  );
};

export const handleCustomRestrictionPolicyChange = (
  selectedApplications: string[],
  value: string,
  dispatch: any,
) => {
  if (selectedApplications.length === 1) {
    const app = selectedApplications[0];
    dispatch(
      updateCustomRestrictionPolicy({
        key: app,
        value,
      }),
    );
  }
  if (selectedApplications.length > 1) {
    dispatch(
      updateBulkCustomRestrictionPolicy({
        value,
        apps: selectedApplications,
      }),
    );
  }
};

export const handleSearch = (
  searchValue: string,
  allUsers: any[],
  filteredUsers: any[],
  setFilteredUsers: (users: any[]) => void,
  column?: string,
) => {
  const lowercasedValue = searchValue.toLowerCase().trim();
  const usersArray = Array.isArray(allUsers) ? allUsers : [];

  if (!lowercasedValue) {
    setFilteredUsers(usersArray);
    return;
  }
  const filtered = usersArray.filter((user) => {
    if (column && column !== 'All') {
      return user[column]?.toString().toLowerCase().includes(lowercasedValue);
    }
    return Object.values(user).some((value) => value?.toString().toLowerCase().includes(lowercasedValue));
  });
  setFilteredUsers(filtered);
};
