import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }: any) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '1.5rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.5rem !important',
      padding: '0.313rem 0.438rem !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: '1.85rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.625rem !important',
      padding: '0.313rem 0.438rem !important',
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: '2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.813rem !important',
      padding: '0.344rem 0.875rem !important',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    height: '2.2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.938rem !important',
      padding: '0.344rem 0.875rem !important',
    },
  },
}));

export const CustomizedTextField = styled(TextField)(({ theme }: any) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '2.2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.5rem !important',
      padding: '0.225rem 1.525rem !important',
      // padding: '0.313rem 0.438rem !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: '2.2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '0.825rem !important',
      padding: '3.125 3.125 !important',
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: '2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '1rem !important',
      padding: '1.125rem 1.125rem !important',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    height: '2.7rem !important',
    '& .MuiInputBase-input': {
      fontSize: '1.0rem !important',
      padding: '0.5rem 12.2px !important',
      backgroundColor: 'none',
      border: '0.188rem solid white',
    },
  },
}));
