import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getMostRestrictedUsersData,
  getRestrictions,
  getSeverityInsights,
} from './auditingHandler';

// interface FilterRow {
//   id: number;
//   fromDate?: string;
//   toDate?: string;
//   selectedField: string;
//   selectedMultipleFields?: string[];
//   chipList: string[];
// }

interface InitialState {
  status: 'idle' | 'loading' | 'succeeded';
  severityDate: string;
  userEmail: string;
  severityInsights: {};
  restrictedUsers: any[];
  totalRestrictions: any[];
}

const initialState: InitialState = {
  status: 'idle',
  userEmail: '',
  severityDate: '',
  severityInsights: [],
  restrictedUsers: [],
  totalRestrictions: [],
};

const auditingDashboard = createSlice({
  name: 'auditingDashboard',
  initialState,
  reducers: {
    updateSeverityDate: (state, action: PayloadAction<string>) => {
      state.severityDate = action.payload;
    },
    updateUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    resetUserEmail: (state) => {
      state.userEmail = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSeverityInsights.fulfilled, (state, action) => {
        state.severityInsights = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getSeverityInsights.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMostRestrictedUsersData.fulfilled, (state, action) => {
        state.restrictedUsers = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getMostRestrictedUsersData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRestrictions.fulfilled, (state, action) => {
        state.totalRestrictions = action.payload;
        state.status = 'succeeded';
      });
  },
});

export const {
  updateSeverityDate,
  updateUserEmail,
  resetUserEmail,
} = auditingDashboard.actions;
export default auditingDashboard;
