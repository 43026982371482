import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, Tabs, Tab,
} from '@mui/material';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import { apps } from '../app';
import { RootState, AppDispatch } from '../../../Redux/store';
import {
  updateEditScreen,
  getAllGroups,
} from '../../../Redux/Slices/Application/appSlice';
import { ResponsiveImage } from '../../../styles/app-logo';
import { ResponsiveHeading } from '../../../styles/heading';
import { lightBackgroundButtonStyles } from '../../../styles/button';
import { buttonIconStyles } from '../../../styles/icon';
import { ResponsiveTypography } from '../../../styles/inputLabel';

interface IProps {
  app: string;
}

function EditScreen({ app }: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { editScreen, editPageName } = useSelector(
    (state: RootState) => state.application,
  );
  const { enable } = useSelector(
    (state: RootState) => state.basicSettings.deviceManagement,
  );
  useEffect(() => {
    dispatch(getAllGroups());
    dispatch(updateEditScreen(editPageName));
  }, []);
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={{ padding: { xl: '1.5rem', lg: '1rem' } }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          gap="1rem"
          pr="1.5rem"
          alignItems="center"
        >
          <ResponsiveImage src={apps[app].smallLogo} alt="application-logo" />
          <ResponsiveHeading>{apps[app].title}</ResponsiveHeading>
        </Box>
        <Box>
          <Button
            variant="text"
            sx={{
              ...lightBackgroundButtonStyles,
              width: '12rem',
              height: '2.6rem !important',
              marginRight: '5rem',
              border: '0.125rem solid #0065FF',
              color: '#0065FF',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'transparent',
                border: '0.125 solid #0065FF',
                color: '#0065FF',
              },
            }}
            startIcon={(
              <ExtensionOutlinedIcon
                sx={{ ...buttonIconStyles, color: '#0065FF' }}
              />
            )}
            onClick={() => window.open(
              'https://chromewebstore.google.com/detail/miniorange-casb/bppbenlhlfiiaaeopoaifkgoibonfkbn',
              '_blank',
            )}
          >
            Download Extension
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        sx={{ paddingTop: { xl: '2.5rem', md: '1.25rem' } }}
      >
        <Box
          sx={{ padding: { xl: '1.5rem', md: '1rem' } }}
          border="0.063rem solid #EAEAEA"
          borderRadius="0.625rem"
          p="1rem"
          width={editScreen === 'BasicSettings' ? '50%' : '100%'}
        >
          <Tabs
            value={editScreen}
            onChange={(event: React.SyntheticEvent, newValue: string) => dispatch(updateEditScreen(newValue))}
          >
            <Tab
              label={
                <ResponsiveTypography>Basic Settings</ResponsiveTypography>
              }
              value="BasicSettings"
            />
            <Tab
              label={
                <ResponsiveTypography>Group Settings</ResponsiveTypography>
              }
              value="GroupSettings"
            />
            <Tab
              label={
                <ResponsiveTypography>Session Management</ResponsiveTypography>
              }
              value="SessionManagement"
            />
            {apps[app].title !== 'Shopify' && enable && (
              <Tab
                label={
                  <ResponsiveTypography>Device Management</ResponsiveTypography>
                }
                value="DeviceManagement"
              />
            )}
          </Tabs>
          <Box>
            {editScreen === 'BasicSettings'
              && apps[app].editScreen?.basicSettings}
            {editScreen === 'GroupSettings'
              && apps[app].editScreen?.groupSettings}
            {editScreen === 'SessionManagement'
              && apps[app].editScreen?.session}
            {editScreen === 'DeviceManagement'
              && apps[app].editScreen?.deviceManagement}
          </Box>
        </Box>
        <Box
          py="1rem"
          px="5rem"
          display={editScreen === 'BasicSettings' ? 'flex' : 'none'}
          width="50%"
        >
          {apps[app].configurationScreen?.basicSettingsInfo}
        </Box>
      </Box>
    </Box>
  );
}
export default EditScreen;
