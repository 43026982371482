import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getCookie } from '../../../../../utils/utility';

interface State {
  identifier: string;
  description: string;
  policy: string;
  apps: any[];
  allPolicies: any[];
  defaultPolicyID: string;
  restrictedApps: string[];
  allowedApps: string[];
  blockApps: string[];
  customRestrictionApps: Record<string, string>;
  selectedApplications: string[];
  type: string;
  _id: string;
  atlassian: {
    jiraBoard: { name: string; access: boolean }[];
    defaultJiraAccess: boolean;
    confluenceBoard: { name: string; access: boolean }[];
    defaultConfluenceAccess: boolean;
  };
  office365: {
    onedrive: {
      disableFileDelete: boolean;
      disableFileCopy: boolean;
    };
    sharepoint: {
      disableFileDelete: boolean;
      disableFileCopy: boolean;
    };
  };
  close: boolean;
}

const initialState: State = {
  identifier: '',
  description: '',
  policy: '',
  apps: [],
  allPolicies: [],
  defaultPolicyID: '',
  restrictedApps: [],
  allowedApps: [],
  blockApps: [],
  customRestrictionApps: {},
  selectedApplications: [],
  _id: '',
  type: 'save',
  atlassian: {
    jiraBoard: [],
    confluenceBoard: [],
    defaultConfluenceAccess: false,
    defaultJiraAccess: false,
  },
  office365: {
    onedrive: {
      disableFileDelete: false,
      disableFileCopy: false,
    },
    sharepoint: {
      disableFileDelete: false,
      disableFileCopy: false,
    },
  },
  close: false,
};

export const getGroupApps = createAsyncThunk(
  'getGroupApps',
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const token = await getCookie('token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-apps`,
        {
          app: state.application.app,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const getConfiguredPolicies = createAsyncThunk(
  'getConfiguredPolicies',
  async () => {
    try {
      const token = await getCookie('token');
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      );

      if (response && response.data && response.data.PolicyData) {
        return response.data.PolicyData;
      }
      return [];
    } catch (error) {
      toast.error('Error loading Policies');
      throw error;
    }
  },
);

const manageGroupSlice = createSlice({
  name: 'groupSettings',
  initialState,
  reducers: {
    updateIdentifier: (state, action: PayloadAction<string>) => {
      state.identifier = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    removeApp: (state, action: PayloadAction<string>) => {
      const app = action.payload;
      state.restrictedApps = state?.restrictedApps?.filter(
        (item: string) => item !== app,
      );
      state.allowedApps = state?.allowedApps?.filter(
        (item: string) => item !== app,
      );
      state.blockApps = state?.blockApps?.filter(
        (item: string) => item !== app,
      );
      delete state?.customRestrictionApps?.[app];
    },
    updateAppRestrictionList: (state, action: PayloadAction<string>) => {
      state.restrictedApps.push(action.payload);
    },
    updateAppAllowedList: (state, action: PayloadAction<string>) => {
      state.allowedApps.push(action.payload);
    },
    updateAppBlockedList: (state, action: PayloadAction<string>) => {
      state.blockApps.push(action.payload);
    },
    updateCustomRestrictionList: (state, action: PayloadAction<string>) => {
      state.customRestrictionApps[action.payload] = state.defaultPolicyID;
    },
    updateCustomRestrictionPolicy: (
      state,
      action: PayloadAction<{ key: string; value: string }>,
    ) => {
      const { key, value } = action.payload;
      state.customRestrictionApps[key] = value;
    },
    updateGroupPolicy: (state, action: PayloadAction<string>) => {
      state.policy = action.payload;
    },
    resetSelectedApplication: (state) => {
      state.selectedApplications = [];
    },
    selectedAllApplications: (state) => {
      state.apps.forEach((element) => {
        if (!state.selectedApplications.includes(element.appKey)) {
          state.selectedApplications.push(element.appKey);
        }
      });
    },
    updateSelectedApplications: (
      state,
      action: PayloadAction<{ appKey: string; checked: boolean }>,
    ) => {
      const { appKey, checked } = action.payload;
      if (checked) {
        state.selectedApplications.push(appKey);
      } else {
        state.selectedApplications = state.selectedApplications?.filter(
          (item: string) => item !== appKey,
        );
      }
    },
    updateClose: (state, action: PayloadAction<boolean>) => {
      state.close = action.payload;
    },
    addNewJiraBoard: (state) => {
      state.atlassian.jiraBoard.push({ name: '', access: false });
    },
    updateBoardIdentifier: (
      state,
      action: PayloadAction<{ identifier: string; index: number }>,
    ) => {
      const { identifier, index } = action.payload;
      if (state.atlassian.jiraBoard[index]) {
        state.atlassian.jiraBoard[index].name = identifier;
      }
    },
    updateBoardAccess: (
      state,
      action: PayloadAction<{ checked: boolean; index: number }>,
    ) => {
      const { checked, index } = action.payload;
      if (state.atlassian.jiraBoard[index]) {
        state.atlassian.jiraBoard[index].access = checked;
      }
    },
    removeJiraBoard: (state, action: PayloadAction<number>) => {
      const indexToRemove = action.payload;
      if (
        indexToRemove >= 0
        && indexToRemove < state.atlassian.jiraBoard.length
      ) {
        state.atlassian.jiraBoard.splice(indexToRemove, 1);
      }
    },
    removeConfluenceBoard: (state, action: PayloadAction<number>) => {
      const indexToRemove = action.payload;
      if (
        indexToRemove >= 0
        && indexToRemove < state.atlassian.confluenceBoard.length
      ) {
        state.atlassian.confluenceBoard.splice(indexToRemove, 1);
      }
    },
    addNewConfluenceBoard: (state) => {
      state.atlassian.confluenceBoard.push({ name: '', access: false });
    },
    updateSpaceIdentifier: (
      state,
      action: PayloadAction<{ identifier: string; index: number }>,
    ) => {
      const { identifier, index } = action.payload;
      if (state.atlassian.confluenceBoard[index]) {
        state.atlassian.confluenceBoard[index].name = identifier;
      }
    },
    updateSpaceAccess: (
      state,
      action: PayloadAction<{ checked: boolean; index: number }>,
    ) => {
      const { checked, index } = action.payload;
      if (state.atlassian.confluenceBoard[index]) {
        state.atlassian.confluenceBoard[index].access = checked;
      }
    },
    updateDefaultConfluenceAccess: (state, action: PayloadAction<boolean>) => {
      state.atlassian.defaultConfluenceAccess = action.payload;
    },
    updateDefaultJiraAccess: (state, action: PayloadAction<boolean>) => {
      state.atlassian.defaultJiraAccess = action.payload;
    },
    updateOneDriveFileDelete: (state, action: PayloadAction<boolean>) => {
      state.office365.onedrive.disableFileDelete = action.payload;
    },
    updateOneDriveFileCopy: (state, action: PayloadAction<boolean>) => {
      state.office365.onedrive.disableFileCopy = action.payload;
    },
    updateSharePointFileDelete: (state, action: PayloadAction<boolean>) => {
      state.office365.sharepoint.disableFileDelete = action.payload;
    },
    updateSharePointFileCopy: (state, action: PayloadAction<boolean>) => {
      state.office365.sharepoint.disableFileCopy = action.payload;
    },
    updateGroupConfiguration: (state, action: PayloadAction<any>) => {
      const groupSettings = action.payload;
      state.identifier = groupSettings.identifier;
      state.description = groupSettings.description;
      state.policy = groupSettings.policy_id;
      state.allowedApps = !groupSettings.app_allowed_list
        ? []
        : groupSettings.app_allowed_list;
      state.restrictedApps = !groupSettings.app_restriction_list
        ? []
        : groupSettings.app_restriction_list;
      state.blockApps = !groupSettings.app_blocked_list
        ? []
        : groupSettings.app_blocked_list;
      state.customRestrictionApps = !groupSettings.app_custom_restriction_list
        ? {}
        : groupSettings.app_custom_restriction_list;
      state.atlassian.jiraBoard = groupSettings?.granular_restriction?.jira?.project_access?.boards;
      state.atlassian.defaultJiraAccess = groupSettings?.granular_restriction?.jira?.project_access?.parent_access;
      state.atlassian.confluenceBoard = groupSettings?.granular_restriction?.confluence?.space_access?.list;
      state.atlassian.defaultJiraAccess = groupSettings?.granular_restriction?.confluence?.space_access?.parent_access;
      state.office365.onedrive.disableFileCopy = groupSettings?.granular_restriction?.onedrive?.disable_file_copy;
      state.office365.onedrive.disableFileDelete = groupSettings?.granular_restriction?.onedrive?.disable_file_delete;
      state.office365.sharepoint.disableFileCopy = groupSettings?.granular_restriction?.sharepoint?.disable_file_copy;
      state.office365.sharepoint.disableFileDelete = groupSettings?.granular_restriction?.sharepoint?.disable_file_delete;
      state._id = groupSettings._id;
      state.type = 'update';
    },
    updateRestrictedAppList: (state) => {
      state.apps?.forEach((element: any) => {
        state.restrictedApps.push(element.appKey);
      });
      state.allowedApps = [];
      state.blockApps = [];
      state.customRestrictionApps = {};
    },
    resetGroupConfiguration: (state) => {
      state.identifier = '';
      state.description = '';
      state.policy = state.defaultPolicyID;
      state.allowedApps = [];
      state.blockApps = [];
      state.customRestrictionApps = {};
      state.apps?.forEach((element: any) => {
        state.restrictedApps.push(element.appKey);
      });
      state.atlassian.jiraBoard = [];
      state.atlassian.defaultJiraAccess = false;
      state.atlassian.confluenceBoard = [];
      state.atlassian.defaultJiraAccess = false;
      state.office365.onedrive.disableFileCopy = false;
      state.office365.onedrive.disableFileDelete = false;
      state.office365.sharepoint.disableFileCopy = false;
      state.office365.sharepoint.disableFileDelete = false;
      state._id = '';
      state.type = 'save';
    },
    clearGroupSettings: (state) => {
      state.identifier = '';
      state.description = '';
      state.policy = state.defaultPolicyID;
      state.allowedApps = [];
      state.blockApps = [];
      state.restrictedApps = [];
      state.customRestrictionApps = {};
      state.atlassian.jiraBoard = [];
      state.atlassian.defaultJiraAccess = false;
      state.atlassian.confluenceBoard = [];
      state.atlassian.defaultJiraAccess = false;
      state.office365.onedrive.disableFileCopy = false;
      state.office365.onedrive.disableFileDelete = false;
      state.office365.sharepoint.disableFileCopy = false;
      state.office365.sharepoint.disableFileDelete = false;
      state._id = '';
      state.type = 'save';
    },
    updateBulkCustomRestrictionPolicy: (
      state,
      action: PayloadAction<{ apps: string[]; value: string }>,
    ) => {
      const { apps, value } = action.payload;
      apps.forEach((app: string) => {
        state.customRestrictionApps[app] = value;
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGroupApps.fulfilled, (state, action) => {
        state.apps = action.payload.apps;
        const appKeyToAdd = action.payload.apps?.[0]?.appKey;
        if (
          appKeyToAdd
          && !state.selectedApplications.some((appKey) => appKey === appKeyToAdd)
        ) {
          state.selectedApplications.push(appKeyToAdd);
        }
      })
      .addCase(getConfiguredPolicies.fulfilled, (state, action) => {
        state.allPolicies = action.payload;
        action.payload.forEach((policy: any) => {
          if (policy.identifier === 'default') {
            state.defaultPolicyID = policy._id;
            if (!policy) {
              state.policy = policy._id;
            }
          }
        });
      });
  },
});

export const {
  updateIdentifier,
  updateDescription,
  removeApp,
  updateAppRestrictionList,
  updateAppAllowedList,
  updateAppBlockedList,
  updateCustomRestrictionList,
  updateGroupPolicy,
  updateCustomRestrictionPolicy,
  updateBulkCustomRestrictionPolicy,
  updateSelectedApplications,
  addNewJiraBoard,
  updateBoardIdentifier,
  updateBoardAccess,
  addNewConfluenceBoard,
  updateSpaceIdentifier,
  updateSpaceAccess,
  updateDefaultJiraAccess,
  updateDefaultConfluenceAccess,
  removeJiraBoard,
  removeConfluenceBoard,
  updateGroupConfiguration,
  resetGroupConfiguration,
  updateRestrictedAppList,
  updateClose,
  resetSelectedApplication,
  selectedAllApplications,
  clearGroupSettings,
  updateOneDriveFileCopy,
  updateOneDriveFileDelete,
  updateSharePointFileCopy,
  updateSharePointFileDelete,
} = manageGroupSlice.actions;
export default manageGroupSlice;
