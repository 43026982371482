import { createAsyncThunk } from '@reduxjs/toolkit';
import * as KJUR from 'jsrsasign';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
  isValidURL,
  isPEMCertificateValid,
  getCookie,
} from '../../../../utils/utility';
import {
  fetchAllAuthentications,
  updateOpenDrawer,
} from './authenticationSourceSlice';
import {
  nextScreen,
  updateApplicationAuthenticationID,
  updateIDPMetadata,
} from '../appSlice';

const validateInput = (state: any) => {
  if (
    !state.authenticationSource
    || !state.authenticationSource.identifier
    || !state.authenticationSource.issuer
    || !state.authenticationSource.loginURL
    || !state.authenticationSource.logoutURL
    || !state.authenticationSource.bindingType
    || !state.authenticationSource.X509Certificate
  ) {
    toast.error('Empty Input Fields');
    return false;
  }

  if (
    !isValidURL(state.authenticationSource.issuer)
    || !isValidURL(state.authenticationSource.loginURL)
    || !isValidURL(state.authenticationSource.logoutURL)
  ) {
    toast.error('Invalid URL');
    return false;
  }

  return true;
};

const validateCertificate = (cert: any, state: any) => {
  try {
    cert.readCertPEM(state.authenticationSource.X509Certificate);
    const issuer = cert.getIssuerString();
    if (!issuer) {
      toast.error('Certificate may be corrupt or in an incorrect format.');
      return false;
    }
  } catch {
    toast.error('Certificate may be corrupt or in an incorrect format.');
    return false;
  }

  return true;
};

const configureAuthenticationSource = createAsyncThunk(
  'configureAuthenticationSource',
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      if (!validateInput(state)) return false;
      const cert = new KJUR.X509();
      if (!isPEMCertificateValid(state.authenticationSource.X509Certificate)) {
        toast.error('Header and Footer Missing');
        return false;
      }

      if (!validateCertificate(cert, state)) return false;

      const token = await getCookie('token');
      const url = !state.authenticationSource._id
        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`
        : `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`;

      const data = {
        identifier: state.authenticationSource.identifier,
        loginURL: state.authenticationSource.loginURL,
        logoutURL: state.authenticationSource.logoutURL,
        issuer: state.authenticationSource.issuer,
        X509Certificate: state.authenticationSource.X509Certificate,
        bindingType: state.authenticationSource.bindingType,
        _id: state.authenticationSource._id,
      };

      await axios
        .post(
          url,
          {
            data,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async () => {
          toast.success('Authentication Source Configured.');
          await thunkAPI.dispatch(updateOpenDrawer(false));
          await thunkAPI.dispatch(fetchAllAuthentications());
          return true;
        });
      return false;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const deleteAuthenticationSource = createAsyncThunk(
  'authentication/deleteAuthenticationSource',
  async (authenticationID: string, { rejectWithValue, dispatch }) => {
    try {
      const token = await getCookie('token');
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-authentication-source`,
          {
            id: authenticationID,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async () => {
          toast.success('Deletion successful.');
          await dispatch(fetchAllAuthentications());
        });
      return rejectWithValue('Failed Source Deletion.');
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessage = err.response.data.errors[0].message;
        toast.error(errorMessage);
        return rejectWithValue(errorMessage);
      }
      toast.error('Policy deletion unsuccessful.');
      return rejectWithValue(err.message);
    }
  },
);

const deleteBulkAuthenticationSource = createAsyncThunk(
  'authentication/deleteBulkAuthenticationSource',
  async (authenticationIds: string[], { rejectWithValue, dispatch }) => {
    try {
      const token = await getCookie('token');
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-bulk-authentication-source`,
          {
            ids: authenticationIds,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async () => {
          toast.success('Deletion successful.');
          await dispatch(fetchAllAuthentications());
        });
      return rejectWithValue('Failed Bulk Source Deletion.');
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessage = err.response.data.errors[0].message;
        toast.error(errorMessage);
        return rejectWithValue(errorMessage);
      }
      toast.error('Source deletion unsuccessful.');
      return rejectWithValue(err.message);
    }
  },
);

const updateApplicationAuthenticationSource = createAsyncThunk(
  'updateApplicationAuthenticationSource',
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      if (!validateInput(state)) return '';

      const cert = new KJUR.X509();
      if (!isPEMCertificateValid(state.authenticationSource.X509Certificate)) {
        toast.error('Header and Footer Missing');
        return '';
      }

      if (!validateCertificate(cert, state)) return '';

      const token = await getCookie('token');
      const url = !state.authenticationSource._id
        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`
        : `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`;

      const data = {
        identifier: state.authenticationSource.identifier,
        loginURL: state.authenticationSource.loginURL,
        issuer: state.authenticationSource.issuer,
        logoutURL: state.authenticationSource.logoutURL,
        X509Certificate: state.authenticationSource.X509Certificate,
        bindingType: state.authenticationSource.bindingType,
        _id: state.authenticationSource._id,
      };

      await axios
        .post(
          url,
          {
            data,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async (res: any) => {
          const id = res?.data?.authenticationSourceData?._id
            || res?.data?.authenticationDetails?._id;
          await axios
            .post(
              `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
              {
                authentication_id: id,
                app: state.application.app,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(async (response: any) => {
              await thunkAPI.dispatch(
                updateIDPMetadata(
                  response.data.data.authentication_saml_idp_metadata,
                ),
              );
              await thunkAPI.dispatch(updateApplicationAuthenticationID(id));
              await thunkAPI.dispatch(fetchAllAuthentications());
              toast.success('Authentication Source Configured');
              await thunkAPI.dispatch(nextScreen());
            });
          return id;
        })
        .catch((err: any) => {
          if (err.response && err.response.data && err.response.data.errors) {
            return thunkAPI.rejectWithValue(
              err.response.data.errors[0].message,
            );
          }
          throw err;
        });
      return false;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export {
  configureAuthenticationSource,
  deleteAuthenticationSource,
  deleteBulkAuthenticationSource,
  updateApplicationAuthenticationSource,
};
