import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Grid, MenuItem, Button,
} from '@mui/material';
import LastPageIcon from '@mui/icons-material/LastPage';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';
import {
  updateIdentifier,
  updateDescription,
  updateGroupPolicy,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { buttonIconStyles } from '../../../../../../styles/icon';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import { getAllPolicies } from '../../../../../../Redux/Slices/Policy/policyHandler';
import { redirectToEditScreen } from '../../../../../../Redux/Slices/Application/appSlice';
import { apps } from '../../../../app';
import Cancel from '../../../../../../components/cancelButton';

interface SingletonAppGroupSettingsProps {
  editScreen: boolean;
}

function SingletonAppGroupSettings({
  editScreen = false,
}: SingletonAppGroupSettingsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier, description, policy, defaultPolicyID,
  } = useSelector(
    (state: RootState) => state.groupSettings,
  );
  const { allPolicies } = useSelector(
    (state: RootState) => state.policySettings,
  );

  useEffect(() => {
    dispatch(getAllPolicies());
  }, [dispatch]);

  return (
    <Box>
      <Grid container pb="1rem" rowGap="1rem">
        <Grid item xs={5}>
          <ResponsiveTypography>Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '0.938rem',
              },
            }}
            name="identifier"
            value={identifier || ''}
            onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
          />
        </Grid>
        <Grid item xs={5}>
          <ResponsiveTypography>Description</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '0.938rem',
              },
            }}
            name="description"
            value={description || ''}
            onChange={(event: any) => dispatch(updateDescription(event.target.value))}
          />
        </Grid>
        <Grid item xs={5}>
          <ResponsiveTypography>Policy</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledSelect
            value={policy || defaultPolicyID}
            onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
          >
            {allPolicies.map((source: any) => (
              <MenuItem key={source.identifier} value={source._id}>
                {source.identifier}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <Box display="flex" width="100%" mt={1} gap="1rem">
        {apps?.isSingletonApplication && (
          <Button
            variant="text"
            sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
            onClick={() => dispatch(redirectToEditScreen(true))}
          >
            Skip
          </Button>
        )}
        <Cancel />
        {!editScreen && (
          <Button
            variant="text"
            sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
            onClick={() => dispatch(redirectToEditScreen(true))}
          >
            Skip
          </Button>
        )}
        {/* <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
        >
          <DeleteOutlineOutlinedIcon
            sx={{ ...buttonIconStyles, color: '#373737' }}
          />
          Cancel
        </Button> */}
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(configureNewGroup())}
        >
          Finish
          <LastPageIcon sx={{ ...buttonIconStyles, color: 'white' }} />
        </Button>
      </Box>
    </Box>
  );
}

export default SingletonAppGroupSettings;
