import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  // TextFieldProps,
  // Dialog,
  IconButton,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  // Checkbox,
  // FormControlLabel,
  // FormGroup,
  // Divider,
  // Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  // OutlinedInput,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TuneIcon from '@mui/icons-material/Tune';
// import { CSVLink } from 'react-csv';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import MainLayout from '../../components/MainLayout';
import {
  // StyledSelect,
  CustomizedSelect,
} from '../../styles/select';
import { ResponsiveHeading } from '../../styles/heading';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../styles/button';
import {
  // StyledTextField,
  CustomizedTextField,
} from '../../styles/textField';
// import { buttonIconStyles } from '../../styles/icon';
// import { ResponsiveSubHeading } from '../../styles/subHeading';
import AuditingCommonTable from '../../components/Table/AuditingCommonTable';
import { handleSearch } from '../../utils/Groups/groupUtility';
import {
  updateEmailAddresses,
  updateAppAccesses,
  updateCasbApp,
  // updateGroupBy,
  // updateGroupIds,
  updateIPAddresses,
  // updateLimit,
  updateMacAddresses,
  // updatePage,
  updateStatusArray,
  updateSelectedDateButton,
  resetStatusArray,
  resetAppAccesses,
  // resetAuditingDashboard,
  resetCasbApp,
  resetEmailAddresses,
  // resetGroupBy,
  resetIPAddresses,
  // resetGroupIds,
  resetMacAddresses,
  updateEndTime,
  updateStartTime,
  updateFilterBox,
  resetPage,
  setFilterRows,
  resetStartTime,
  resetEndTime,
  resetSelectedDateButton,
  setApiType,
} from '../../Redux/Slices/Auditing/PersistState/preferences';
import { AppDispatch, RootState } from '../../Redux/store';
import { fetchRestrictionPerDay } from '../../Redux/Slices/Auditing/PersistState/preferencesHandler';
import { statusMapping } from '../ApplicationInsights/appInsights';

const menuButtonStyles = {
  fontSize: '2rem',
  color: 'gray',
  cursor: 'pointer',
  transition: 'color 0.3s ease, transform 0.2s ease',
};
type FieldOption = {
  value: string;
  key: string;
};
export const getKeysFromValues = (values: string[]): string[] => {
  const keys = Object.keys(statusMapping); // Get all keys from statusMapping
  const result: string[] = [];

  values.forEach((value) => {
    const matchingKey = keys.find(
      (key) => statusMapping[key].trim().toLowerCase() === value.trim().toLowerCase(),
    ); // Normalize case and trim whitespace
    if (matchingKey) {
      result.push(matchingKey); // Add the matching key to the result array
    }
  });

  console.log('Input values:', values); // Debug log input values
  console.log('Matched keys:', result); // Debug log matched keys

  return result;
};

export function resetState(dispatch: AppDispatch) {
  dispatch(resetIPAddresses());
  dispatch(resetPage());
  dispatch(resetEmailAddresses());
  dispatch(resetCasbApp());
  dispatch(resetAppAccesses());
  dispatch(resetMacAddresses());
  dispatch(resetStatusArray());
  dispatch(resetStartTime());
  dispatch(resetEndTime());
  dispatch(resetSelectedDateButton());
  dispatch(setApiType('field'));
  dispatch(setFilterRows([{ id: Date.now() }]));
  dispatch(fetchRestrictionPerDay());
}

export default function UserInsights() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    totalUsers,
    selectedDateButton,
    startTime,
    endTime,
    showFilterBox,
    filterRows,
  } = useSelector((state: RootState) => state.preferences);

  console.log('totalUsers', totalUsers);

  const [filteredUsers, setFilteredUsers] = useState(totalUsers);
  const [searchValue, setSearchValue] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [fromDate] = useState<string>('');
  const [toDate] = useState<string>('');

  useEffect(() => {
    dispatch(fetchRestrictionPerDay());
  }, []);
  useEffect(() => {
    setFilteredUsers(totalUsers);
  }, [totalUsers]);

  useEffect(() => {
    const updatedUsers = totalUsers.map((user) => ({
      ...user,
      status: statusMapping[user.status] || user.status,
    }));
    setFilteredUsers(updatedUsers);
  }, [totalUsers]);

  const dateOptions = [
    { label: 'Today', value: 0 },
    { label: 'Yesterday', value: 1 },
    { label: 'Last Week', value: 7 },
    { label: 'Last Month', value: 30 },
    { label: 'Last Year', value: 365 },
  ];

  const fieldOptions: FieldOption[] = [
    { key: 'User', value: 'user' },
    // { key: 'Policy', value: 'policies' },
    { key: 'IP', value: 'ip_addresses' },
    { key: 'Accessed Apps', value: 'appAccessed' },
    { key: 'CASB App Service', value: 'casbApp' },
    // { key: 'MAC address', value: 'macAddresses' },
    // { key: 'Restriction Count', value: 'restriction_count' },
    { key: 'Application Status', value: 'status' },
  ];

  const handleFilterIconClick = () => {
    dispatch(updateFilterBox(!showFilterBox));
  };
  const handleClearAll = () => {
    resetState(dispatch);
  };
  const handleAddNewFilterRow = () => {
    // setFilterRows([...filterRows, { id: Date.now() }]);
    dispatch(setFilterRows([...filterRows, { id: Date.now() }]));
  };

  const handleSingleSelectChange = (
    event: SelectChangeEvent<string>,
    index: number,
  ) => {
    const selectedValue = event.target.value;
    const updatedRows = [...filterRows];
    const previousField = updatedRows[index]?.selectedField;
    if (previousField) {
      switch (previousField) {
        case 'user':
          dispatch(resetEmailAddresses());
          break;
        case 'ip_addresses':
          dispatch(resetIPAddresses());
          break;
        case 'appAccessed':
          dispatch(resetAppAccesses());
          break;
        case 'casbApp':
          dispatch(resetCasbApp());
          break;
        case 'macAddresses':
          dispatch(resetMacAddresses());
          break;
        case 'status':
          dispatch(resetStatusArray());
          break;
        default:
          console.warn('Unhandled field for reset:', previousField);
      }
    }
    // setFilterRows(updatedRows);

    updatedRows[index] = {
      ...updatedRows[index],
      selectedField: selectedValue,
      chipList: [],
    };

    // setFilterRows(updatedRows);
    dispatch(setFilterRows(updatedRows));
  };
  console.log('filterRows-->', filterRows);

  // useEffect(() => {
  //   setFilteredUsers(totalUsers);
  // }, [totalUsers]);

  // const [visibleColumns, setVisibleColumns] = useState({
  //   user: true,
  //   restrictedCount: true,
  //   ipAddresses: true,
  //   polcies: true,
  //   appsAccessed: true,
  // });
  // const [filterRows, setFilterRows] = useState<FilterRow[]>([
  //   { id: Date.now() },
  // ]);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [searchText, setSearchText] = useState('');

  // const [selectedValues, setSelectedValues] = useState({});
  // const handleDialogOpen = () => setOpenDialog(true);
  // const handleDialogClose = () => setOpenDialog(false);
  // interface RestrictedUser {
  //   id: number;
  //   name: string;
  // }
  // const [newFilterRows, setNewFilterRows] = useState<RestrictedUser[]>([]);
  // useEffect(() => {
  //   setNewFilterRows(restrictedUsers);
  // });

  // const handleColumnChange = (column: any) => {
  //   setVisibleColumns((prev) => ({
  //     ...prev,
  //     [column]: !prev[column],
  //   }));
  // };

  // useEffect(() => {
  //   // console.log(selectedValues);
  // }, [selectedValues]);

  // const exportToPDF = () => {
  //   const input = document.getElementById('table-to-pdf');

  //   if (input) {
  //     html2canvas(input, { scale: 2 }).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const Pdf = new jsPDF();
  //       const imgWidth = 190;
  //       const pageHeight = Pdf.internal.pageSize.height;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;

  //       let position = 0;

  //       Pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         Pdf.addPage();
  //         Pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }

  //       Pdf.save('user-login-table.pdf');
  //     });
  //   } else {
  //     console.error("Element with ID 'table-to-pdf' not found.");
  //   }
  // };

  const handleDateSelection = (days: number) => {
    const now = dayjs();
    let selectedFromDate = dayjs();
    switch (days) {
      case 0:
        selectedFromDate = now.startOf('day');
        break;
      case 1:
        selectedFromDate = now.subtract(1, 'day');
        break;
      case 7:
        selectedFromDate = now.subtract(7, 'days');
        break;
      case 30:
        selectedFromDate = now.subtract(1, 'month');
        break;
      case 365:
        selectedFromDate = now.subtract(1, 'year');
        break;
      default:
        break;
    }
    const formattedStartTime = selectedFromDate.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = now.format('YYYY-MM-DD HH:mm:ss');
    dispatch(updateSelectedDateButton(days));
    dispatch(updateStartTime(formattedStartTime.toString()));
    dispatch(updateEndTime(formattedEndDate.toString()));
  };

  const handleDeleteFilterRow = (rowId: number) => {
    const rowToDelete = filterRows.find((row) => row.id === rowId);

    if (rowToDelete) {
      switch (rowToDelete.selectedField) {
        case 'user':
          dispatch(resetEmailAddresses());
          break;
        case 'ip_addresses':
          dispatch(resetIPAddresses());
          break;
        case 'appAccessed':
          dispatch(resetAppAccesses());
          break;
        case 'casbApp':
          dispatch(resetCasbApp());
          break;
        case 'status':
          dispatch(resetStatusArray());
          break;
        case 'macAddress':
          dispatch(resetMacAddresses());
          break;
        default:
          break;
      }
    }
    // setFilterRows(filterRows.filter((row) => row.id !== rowId));
    dispatch(setFilterRows(filterRows.filter((row) => row.id !== rowId)));

    // dispatch(setFilterRows(filterRows.filter((row) => row.id !== rowId)))
  };

  // const handleColumnSelectChange = (
  //   event: React.ChangeEvent<{ value: unknown }>,
  // ) => {
  //   const selectedValue = event.target.value as string;
  //   setSelectedColumn(selectedValue);
  // };

  // const filteredRows = rows.filter((row) => {
  //   if (!selectedColumn) {
  //     return Object.values(row).some((value) => String(value).toLowerCase().includes(searchText.toLowerCase()));
  //   }
  //   return String(row[selectedColumn] || '')
  //     .toLowerCase()
  //     .includes(searchText.toLowerCase());
  // });

  useEffect(() => {
    // setFilterRows((prevRows) => prevRows.map((row) => ({
    //   ...row,
    //   fromDate,
    //   toDate,
    // })));
    const updateRowsWithDate = (
      prevRowsData: any[],
      fromDateData: any,
      toDateData: any,
    ): any[] => prevRowsData.map((row) => ({
      ...row,
      fromDateData,
      toDateData,
    }));
    const updatedRows = updateRowsWithDate(filterRows, fromDate, toDate);
    dispatch(setFilterRows(updatedRows));
  }, [fromDate, toDate, dispatch]);

  const handleChipChange = (
    event: React.ChangeEvent<{}>,
    newValue: string[],
    index: number,
  ) => {
    const { selectedField } = filterRows[index] as {
      selectedField?: string;
      chipList: any[];
    };
    switch (selectedField) {
      case 'user':
        dispatch(updateEmailAddresses(newValue));
        break;
      case 'ip_addresses':
        dispatch(updateIPAddresses(newValue));
        break;
      case 'appAccessed':
        dispatch(updateAppAccesses(newValue));
        break;
      case 'casbApp':
        dispatch(updateCasbApp(newValue));
        break;
      case 'macAddresses':
        dispatch(updateMacAddresses(newValue));
        break;
      // case 'restriction_count':
      //   dispatch(updateStatusArray(newValue));
      //   break;
      case 'status': {
        const formattedNewValue = getKeysFromValues(newValue);
        dispatch(updateStatusArray(formattedNewValue));
        break;
      }
      default:
        console.warn('Unhandled field:', selectedField);
    }

    // setFilterRows((prevRows) => {
    //   const updatedRows = [...prevRows];
    //   updatedRows[index] = {
    //     ...updatedRows[index],
    //     chipList: newValue,
    //   };
    //   return updatedRows;
    // });
    const updateRowChipList = (
      prevRowsData: any[],
      indexData: number,
      newValueData: any,
    ): any[] => {
      const updatedRows = [...prevRowsData];
      updatedRows[indexData] = {
        ...updatedRows[indexData],
        chipList: newValueData,
      };
      return updatedRows;
    };
    const updatedRows1 = updateRowChipList(filterRows, index, newValue);
    dispatch(setFilterRows(updatedRows1));

    const updatedRows = [...filterRows];
    updatedRows[index] = { ...updatedRows[index], chipList: newValue };
  };

  const handleAdvancedSearch = () => {
    dispatch(resetPage());
    dispatch(fetchRestrictionPerDay());
  };

  return (
    <MainLayout>
      <Box
        sx={{
          height: { xl: '90vh', xxl: '90vh' },
          overflowY: 'auto',
          padding: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box p={1} width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // mb={1}
              marginLeft={5}
            >
              <ResponsiveHeading>User Insights</ResponsiveHeading>
            </Box>
            {/* <Divider sx={{ borderColor: 'gray' }} /> */}
          </Box>
        </Box>
        <Box marginLeft={5} marginRight={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="5rem"
          >
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Box>
                <CustomizedTextField
                  id="input-with-icon-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Search for user details"
                  onChange={(event: any) => {
                    const searchTerm = event.target.value;
                    setSearchValue(searchTerm);
                    handleSearch(
                      searchTerm,
                      totalUsers,
                      filteredUsers,
                      setFilteredUsers,
                      selectedColumn,
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{
                            fontSize: { xxl: '2rem', xl: '1.5rem' },
                            padding: '0rem !important',
                          }}
                        />
                      </InputAdornment>
                    ),
                    sx: {
                      height: {
                        xxl: '45px !important',
                        xl: '42px !important',
                        lg: '60px !important',
                      },
                      width: '20rem',
                      color: '#474747 !important',
                      borderRadius: '5px',
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginTop: 1,
                  marginBottom: {
                    xl: 0,
                    xxl: 1,
                    lg: 1,
                  },
                }}
              >
                <CustomizedSelect
                  value={selectedColumn || 'All'}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const selectedValue = event.target.value as string;
                    setSelectedColumn(selectedValue);
                    handleSearch(
                      searchValue,
                      totalUsers,
                      filteredUsers,
                      setFilteredUsers,
                      selectedValue,
                    );
                  }}
                  sx={{
                    ml: 2,
                    minHeight: { xxl: '15px !important' },
                    color: '#474747 !important',
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {fieldOptions.map((field) => (
                    <MenuItem key={field.value} value={field.value}>
                      {field.key}
                    </MenuItem>
                  ))}
                </CustomizedSelect>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: '2rem',
                marginRight: '2rem',
              }}
            >
              <TuneIcon
                onClick={handleFilterIconClick}
                sx={{
                  ...menuButtonStyles,
                  color: '#517bf9',
                }}
              />
            </Box>
          </Box>
          {showFilterBox && (
            <Box
              sx={{
                border: '1px solid #e5e5e5',
                backgroundColor: '#fcfcfc',
                borderRadius: 1,
                padding: 2,
                marginBlock: 2,
                marginRight: 3,
              }}
            >
              <Box gap={2}>
                {/* <ResponsiveSubHeading>Advanced Filter</ResponsiveSubHeading> */}
              </Box>
              <Box display="flex" alignItems="center" gap={5} marginBlock={3}>
                {/* <Box display="flex" alignItems="center" gap={2}> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: {
                        xxl: 'auto',
                        md: 'auto',
                        lg: '180px',
                        xl: '240px',
                      },
                    }}
                  >
                    <DateTimePicker
                      label="From Date and Time"
                      value={startTime ? dayjs(startTime) : null}
                      onChange={(newValue) => {
                        if (newValue && newValue.isValid()) {
                          const formattedStartTime = newValue.format(
                            'YYYY-MM-DD HH:mm:ss',
                          );
                          dispatch(updateStartTime(formattedStartTime));
                        }
                      }}
                      {...(window.innerWidth >= 1200
                        && window.innerWidth <= 1400 && { label: 'From Date' })}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xxl: 'auto',
                        md: 'auto',
                        lg: '180px',
                        xl: '240px',
                      },
                    }}
                  >
                    <DateTimePicker
                      label="To Date and Time"
                      value={endTime ? dayjs(endTime) : null}
                      onChange={(newValue) => {
                        if (newValue && newValue.isValid()) {
                          const formattedEndTime = newValue.format(
                            'YYYY-MM-DD HH:mm:ss',
                          );
                          dispatch(updateEndTime(formattedEndTime));
                        }
                      }}
                      // renderInput={(params) => <TextField {...params} />}
                      {...(window.innerWidth >= 1200
                        && window.innerWidth <= 1400 && { label: 'To Date' })}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Box>
                </LocalizationProvider>
                <Box>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="date selection group"
                  >
                    {dateOptions.map((option) => (
                      <Button
                        key={option.value}
                        onClick={() => handleDateSelection(option.value)}
                        sx={{
                          backgroundColor:
                            selectedDateButton === option.value
                              ? '#0052CC'
                              : 'inherit',
                          color:
                            selectedDateButton === option.value
                              ? '#FFFFFF'
                              : '#0052CC',
                          border: '1px solid #0052CC',
                          // fontWeight : 'bold',
                          borderRadius: 'none !important',
                          '&:hover': {
                            backgroundColor:
                              selectedDateButton === option.value
                                ? '#0052CC'
                                : 'inherit',
                            color:
                              selectedDateButton === option.value
                                ? '#FFFFFF'
                                : '#0052CC',
                          },
                        }}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              </Box>
              {filterRows.map((row, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  sx={{ marginBlock: 4 }}
                  key={row.id}
                >
                  <FormControl
                    sx={{
                      width: '40%',
                      '& .MuiInputBase-root': {
                        height: '45px',
                        backgroundColor: '#ffffff',
                        color: '#474747',
                      },
                    }}
                  >
                    <InputLabel id={`filter-select-label-${row.id}`} />
                    <Select
                      key={row.id}
                      labelId={`filter-select-label-${row.id}`}
                      value={row.selectedField || ''}
                      onChange={(e) => handleSingleSelectChange(e, index)}
                      size="small"
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Select field';
                        }
                        return (
                          fieldOptions.find((field) => field.value === selected)
                            ?.key || selected
                        );
                      }}
                    >
                      {fieldOptions
                        .filter(
                          (field) => !filterRows.some(
                            (r) => r.selectedField === field.value && r !== row,
                          ),
                        )
                        .map((field) => (
                          <MenuItem key={field.value} value={field.value}>
                            {field.key}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <Autocomplete
                    sx={{
                      width: '40%',
                      '& .MuiInputBase-root': {
                        height: '45px',
                        backgroundColor: '#ffffff',
                        alignItems: 'center',
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        minHeight: '45px',
                        flexWrap: 'wrap',
                      },
                      '& .MuiAutocomplete-endAdornment': {
                        alignSelf: 'center',
                      },
                    }}
                    multiple
                    freeSolo
                    size="small"
                    fullWidth
                    id="chips-autocomplete"
                    value={row.chipList || []}
                    onChange={(event, newValue) => handleChipChange(event, newValue, index)}
                    options={
                      row.selectedField !== 'status'
                        ? []
                        : Object.values(statusMapping)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Type and press Enter to add"
                        InputProps={{
                          ...params.InputProps,
                          style: { height: '100%' },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) => value.map((option, tagIndex) => {
                      const { key, ...tagProps } = getTagProps({
                        index: tagIndex,
                      });
                      return (
                        <Chip
                          key={option.id || option}
                          size="medium"
                          label={option}
                          {...tagProps}
                        />
                      );
                    })}
                  />

                  {filterRows.length > 1 && (
                    <IconButton onClick={() => handleDeleteFilterRow(row.id)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ marginTop: 2 }}
              >
                <Box display="flex" gap={2}>
                  <Button
                    variant="text"
                    sx={{
                      ...lightBackgroundButtonStyles,
                      border: '1.5px solid #0052CC !important',
                      fontSize: { lg: '14px !important' },
                    }}
                    onClick={() => handleClearAll()}
                  >
                    Clear All
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleAddNewFilterRow}
                    disabled={filterRows.length >= fieldOptions.length}
                    sx={{
                      ...cyanBlueBackgroundButtonStyles,
                      fontSize: { lg: '14px !important' },
                    }}
                  >
                    <AddIcon />
                    Add New Filter Row
                  </Button>
                </Box>
                <Box display="flex" gap={2}>
                  {/* <Button variant="text" sx={{ color: 'black' }}>
                    Export CSV
                  </Button>
                  <Button variant="text" sx={{ color: 'black' }}>
                    Export Report
                  </Button> */}
                  <Button
                    variant="outlined"
                    sx={{
                      ...cyanBlueBackgroundButtonStyles,
                      width: '80px !important',
                      fontSize: { lg: '14px !important' },
                    }}
                    onClick={() => {
                      handleAdvancedSearch();
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              borderRadius: '1rem',
              marginRight: 3,
              marginTop: showFilterBox ? 4 : 2,
              flexGrow: 1,
            }}
          >
            <AuditingCommonTable
              data={filteredUsers}
              columns={[
                { field: 'user', headerName: 'User' },
                // {
                //   field: 'restriction_count ',
                //   headerName: 'Restriction Count',
                // },
                { field: 'ip', headerName: 'IP Addresses' },
                // { field: 'policies', headerName: 'Policy' },

                // { field: 'service', headerName: 'Application Service' },
                { field: 'casbApp', headerName: 'CASB App' },
                { field: 'appAccessed', headerName: 'Accessed Applications' },
                // { field: 'macAddress', headerName: 'MAC Address'},
                { field: 'status', headerName: 'Application Status' },
                { field: 'timestamp', headerName: 'Timestamp' },
              ]}
              pages="insights"
            />
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}
