import React from 'react';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3e3d32',
    color: '#ffffff',
    fontSize: '14px',
    borderRadius: '8px',
    padding: '8px 12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    maxWidth: 250,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: "'Ubuntu', sans-serif",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3e3d32',
  },
}));

export default CustomTooltip;
