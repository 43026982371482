import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Divider,
  Dialog,
  Breadcrumbs,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PushPinIcon from '@mui/icons-material/PushPin';
import dayjs from 'dayjs';
import MainLayout from '../../components/MainLayout';
import { ResponsiveHeading } from '../../styles/heading';
import { SmallHeading } from '../../styles/smallHeading';
import { apps } from '../Application/app';
import { ResponsiveSubHeading } from '../../styles/subHeading';
import { getGroupApps } from '../../Redux/Slices/Application/AppConfiguration/Group/group';
import { AppDispatch, RootState } from '../../Redux/store';
import { updateApplication } from '../../Redux/Slices/Application/appSlice';
import {
  setBreadcrumbOpen,
  setFilterRows,
  setApiType,
  updateStatusArray,
  updateFilterBox,
  saveKeyValue,
  updateCasbApp,
  updateAppAccesses,
  updateStartTime,
  updateEndTime,
  updateSelectedDateButton,
} from '../../Redux/Slices/Auditing/PersistState/preferences';
import { fetchRestrictionPerDay } from '../../Redux/Slices/Auditing/PersistState/preferencesHandler';
import { resetState } from '../UserInsights/userInsights';
import { renderNoDataMessage } from '../AuditingDashboard/AuditingDashboard';

interface App {
  title: string;
  smallLogo: string;
  imageSrc?: string;
  name?: string;
  isSingletonApplication?: boolean;
}
interface SingletonApplicationsProps {
  appName: string;
  displayedApps: any[];
}

interface filteredRecords {
  [key: string]: any;
}
export const statusMapping: Record<string, string> = {
  // ALLOWED_APP: 'Allowed App',
  IP_BASED_ACCESS_RESTRICTION: 'IP Restriction',
  LOCATION_BASED_ACCESS_RESTRICTION: 'Location Restriction',
  BLOCKED_APP: 'Blocked App',
  FILE_BASED_ACCESS_RESTRICTION: 'File Restriction',
  INVALID_SESSION_ACCESS_RESTRICTION: 'Invalid Session Restriction',
  FILE_FORMAT_BASED_ACCESS_RESTRICTION: 'File Format Restriction',
  OFFICE365_CUSTOM_COPY_FILE_RESTRICTION: 'Office365 Copy File Restriction',
  OFFICE365_CUSTOM_DELETE_FILE_RESTRICTION: 'Office365 Delete File Restriction',
  TIME_BASED_ACCESS_RESTRICTION: 'Time Restriction',
};

function SingletonApplications({
  appName,
  displayedApps,
}: SingletonApplicationsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const singletonColors = ['#efe5fe', '#fef4eb', '#e9fff2', '#eeeeee'];

  const {
    isBreadcrumbOpen,
    totalUsers,
    appAccessed,
    casbApp,
    pinnedAppln,
  } = useSelector((state: RootState) => state.preferences);
  const navigate = useNavigate();
  const [appsWithPinnedStatus, setAppsWithPinnedStatus] = useState<any[]>();
  const [openModal, setOpenModal] = useState(false);
  const [singletonApp, setSingletonApp] = useState<Record<string, any> | null>(
    null,
  );

  const handlePinnedApp = (appKey: string) => {
    const keyValuePairs = [{ key: appName || 'Singleton', values: [appKey] }];
    dispatch(saveKeyValue(keyValuePairs));
  };

  useEffect(() => {
    if (!pinnedAppln || !displayedApps) return;

    const updatedApps = displayedApps
      ?.map((app) => {
        const keysToSearch = [appName || 'Singleton'];
        const filteredData: filteredRecords = keysToSearch.reduce(
          (result, key) => {
            if (pinnedAppln[key]) {
              result[key] = pinnedAppln[key];
            }
            return result;
          },
          {} as filteredRecords,
        );

        const isPinned = Object.values(filteredData).some((appList) => appList.includes(app?.appKey ? app?.appKey : app.title));

        return {
          ...app,
          pinned: isPinned,
        };
      })
      .sort((a, b) => (b.pinned === a.pinned ? 0 : b.pinned ? 1 : -1));

    setAppsWithPinnedStatus(updatedApps);
  }, [pinnedAppln, displayedApps]);
  const handleOpenModal = (app: Record<string, any>): void => {
    setSingletonApp(null);
    dispatch(setFilterRows([{ id: Date.now() }]));
    const now = dayjs();
    let selectedFromDate = dayjs();
    selectedFromDate = now.subtract(1, 'month');
    const formattedStartTime = selectedFromDate.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndTime = now.format('YYYY-MM-DD HH:mm:ss');
    dispatch(updateSelectedDateButton(30));
    dispatch(updateStartTime(formattedStartTime));
    dispatch(updateEndTime(formattedEndTime));

    const appln = isBreadcrumbOpen ? `${app.appKey}` : app.title;
    if (isBreadcrumbOpen) {
      dispatch(updateAppAccesses([appln.toLowerCase()]));
    } else {
      dispatch(updateCasbApp([appln.toLowerCase()]));
    }

    dispatch(setApiType('count'));

    dispatch(fetchRestrictionPerDay()).then(() => {
      setSingletonApp(app);
      setOpenModal(true);
    });
  };

  const handleCloseModal = () => {
    resetState(dispatch);
    dispatch(setApiType('fields'));
    setOpenModal(false);
    setSingletonApp(null);
  };

  const handleSpecificRestriction = (restriction: string) => {
    dispatch(updateStatusArray([restriction]));
    dispatch(setApiType('fields'));
    const filters = [
      {
        id: Date.now(),
        selectedField: isBreadcrumbOpen ? 'appAccessed' : 'casbApp',
        chipList: [isBreadcrumbOpen ? appAccessed : casbApp],
        fromDateData: '',
        toDateData: '',
      },
      {
        id: Date.now() + 1,
        selectedField: 'status',
        chipList: [statusMapping[restriction]],
        fromDateData: '',
        toDateData: '',
      },
    ];
    dispatch(setFilterRows(filters));
    dispatch(updateFilterBox(true));
    dispatch(fetchRestrictionPerDay());
    navigate('/insights');
  };

  return (
    <Box mt={5}>
      {!isBreadcrumbOpen && <SmallHeading>Applications</SmallHeading>}
      <Divider
        sx={{
          my: 2,
          height: '1px',
          backgroundColor: '#ececec',
        }}
      />
      <Box display="flex" flexWrap="wrap" gap={5} marginTop={5}>
        {appsWithPinnedStatus?.map((app, index) => (
          <Box
            key={app.appKey || `${app.title}-${index}`}
            sx={{
              width: '20%',
              height: '275px',
              border: '1px solid #d9d9d9',
              borderRadius: '30px',
            }}
          >
            <Box
              sx={{
                margin: 1,
                height: '70%',
                borderRadius: '30px',
                backgroundColor:
                  singletonColors[index % singletonColors.length],
                border: '1px solid #d9d9d9',
                padding: 2,
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      // image={app.imageSrc}
                      image={
                        isBreadcrumbOpen
                          ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`
                          : app.smallLogo
                      }
                      alt={`${app.title} logo`}
                      sx={{
                        height: '50px',
                        width: '50px',
                      }}
                    />
                    <ResponsiveHeading
                      sx={{
                        fontSize: '20px !important',
                      }}
                    >
                      {isBreadcrumbOpen ? `${app.name}` : app.title}
                    </ResponsiveHeading>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    sx={{
                      borderRadius: '50%',
                      padding: '6px',
                      fontSize: '6px',
                      backgroundColor: app.pinned ? '#0052cc' : '#ffffff',
                      color: app.pinned ? 'white' : '#757575',
                      ':hover': {
                        backgroundColor: app.pinned ? '#0041a8' : '#f0f0f0',
                        color: app.pinned ? 'white' : '#333',
                      },
                      transition:
                        'background-color 0.3s ease, box-shadow 0.3s ease',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePinnedApp(
                        isBreadcrumbOpen ? `${app.appKey}` : `${app.title}`,
                      );
                    }}
                  >
                    <PushPinIcon
                      sx={{
                        fontSize: '18px',
                        transform: app.pinned ? 'rotate(-45deg)' : 'none',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: 1.5,
                paddingY: 1,
              }}
            >
              <ResponsiveSubHeading
                variant="body1"
                sx={{
                  fontSize: '14px',
                  marginBottom: 1,
                }}
              >
                <b />
                {' '}
              </ResponsiveSubHeading>

              <ResponsiveSubHeading
                sx={{
                  fontSize: '14px',
                  color: 'blue',
                  border: '1px solid blue',
                  paddingY: 0.7,
                  paddingX: 2,
                  borderRadius: 6,
                  ':hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                    cursor: 'pointer',
                  },
                }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  handleOpenModal(app);
                }}
              >
                Details
              </ResponsiveSubHeading>
            </Box>
          </Box>
        ))}
      </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            padding: '0.5rem',
            boxShadow: '0rem 0.5rem 1rem rgba(0,0,0,0.12)',
            minWidth: '700px',
            minHeight: '300px',
            borderRadius: '25px',
            height: totalUsers.length > 0 ? '600px' : '300px',
          },
        }}
      >
        <DialogTitle>
          {singletonApp && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              paddingX={1}
            >
              <Box display="flex" alignItems="center" gap={3}>
                <Box>
                  <CardMedia
                    component="img"
                    image={
                      isBreadcrumbOpen
                        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api${singletonApp.imageSrc}`
                        : singletonApp.smallLogo
                    }
                    alt={`${singletonApp.title} logo`}
                    sx={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '12px',
                    }}
                  />
                </Box>
                <Box>
                  <ResponsiveHeading
                    sx={{
                      fontSize: '25px !important',
                    }}
                  >
                    {isBreadcrumbOpen ? singletonApp.name : singletonApp.title}
                  </ResponsiveHeading>
                </Box>
              </Box>

              <IconButton
                edge="end"
                onClick={handleCloseModal}
                sx={{
                  color: '#000',
                  padding: 1,
                  ':hover': {
                    color: 'blue',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogTitle>

        <Box>
          {singletonApp && (
            <DialogContent>
              {totalUsers.length > 0 ? (
                <Box>
                  {totalUsers?.map((user) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={2}
                      sx={{
                        backgroundColor: '#fff8f1',
                        padding: 2,
                        borderRadius: '25px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSpecificRestriction(user.status)}
                    >
                      <Box
                        sx={{
                          width: '70px',
                          height: '70px',
                          borderRadius: '50%',
                          backgroundColor: '#fce3b2',
                          color: 'black',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                          fontSize: '20px',
                          marginRight: 2,
                        }}
                      >
                        {user.count}
                      </Box>

                      <Box
                        sx={{
                          width: '4px',
                          height: '60px',
                          backgroundColor: '#fecb8d',
                          marginRight: 2,
                        }}
                      />
                      <ResponsiveHeading
                        sx={{
                          fontSize: '20px !important',
                        }}
                      >
                        {statusMapping[user.status] || user.status}
                      </ResponsiveHeading>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box mt={4}>{renderNoDataMessage()}</Box>
              )}
            </DialogContent>
          )}
        </Box>
      </Dialog>
    </Box>
  );
}

export default function AppInsights() {
  const { apps: groupApps } = useSelector(
    (state: RootState) => state.groupSettings,
  );
  const { pinnedAppln } = useSelector((state: RootState) => state.preferences);

  const [suiteApps, setSuiteApps] = useState<any[]>([]);
  const [appsWithPinnedStatus, setAppsWithPinnedStatus] = useState<any[]>();
  const dispatch = useDispatch<AppDispatch>();
  const singletonApps = Object.values(apps).filter(
    (app) => app.isSingletonApplication,
  );

  useEffect(() => {
    const filtered = Object.entries(apps)
      .filter(([, app]) => !app.isSingletonApplication)
      .map(([, app], index) => ({
        ...app,
        index,
      }));
    setSuiteApps(filtered);
  }, [apps]);

  const handlePinnedApp = (appKey: string) => {
    const keyValuePairs = [{ key: 'appSuite', values: [appKey] }];
    dispatch(saveKeyValue(keyValuePairs));
  };

  useEffect(() => {
    if (!pinnedAppln || !suiteApps) return;

    const updatedApps = suiteApps
      ?.map((app) => {
        const keysToSearch = ['appSuite'];
        const filteredData: filteredRecords = keysToSearch.reduce(
          (result, key) => {
            if (pinnedAppln[key]) {
              result[key] = pinnedAppln[key];
            }
            return result;
          },
          {} as filteredRecords,
        );
        const isPinned = Object.values(filteredData).some((appList) => appList.includes(app?.appKey ? app?.appKey : app.title));

        return {
          ...app,
          pinned: isPinned,
        };
      })
      .sort((a, b) => (b.pinned === a.pinned ? 0 : b.pinned ? 1 : -1));

    setAppsWithPinnedStatus(updatedApps);
  }, [pinnedAppln, suiteApps]);

  const [displayedApps, setDisplayedApps] = useState<App[]>([]);
  const [selectedApp, setSelectedApp] = useState<any | null>(null);
  const multiColors = ['#defbe9', '#fff1cc', '#dae9ff', '#f4edff'];

  useEffect(() => {
    setDisplayedApps(singletonApps);
    setSelectedApp(null);
    dispatch(setBreadcrumbOpen(false));
  }, []);

  const handleArrowClick = (appName: string) => {
    const selectedAppln = Object.values(apps).find(
      (app) => app.title === appName,
    );
    resetState(dispatch);
    dispatch(setBreadcrumbOpen(true));
    setSelectedApp(selectedAppln);
    dispatch(updateApplication(appName.toLowerCase()));
    dispatch(getGroupApps());
  };

  const handleBreadcrumbClick = () => {
    setSelectedApp(null);
    dispatch(setBreadcrumbOpen(false));
  };

  return (
    <MainLayout>
      <Box
        sx={{
          padding: 2,
          overflow: 'auto',
          maxHeight: '95vh',
        }}
      >
        <Box display="flex" flexDirection="column" mb={2} marginInline={5}>
          <Box p={1} width="100%">
            {!selectedApp && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <ResponsiveHeading>Application Insights</ResponsiveHeading>
              </Box>
            )}
            {selectedApp && (
              <Box mt={2}>
                <Breadcrumbs aria-label="breadcrumb">
                  <SmallHeading
                    underline="hover"
                    onClick={handleBreadcrumbClick}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      '&:hover': {},
                    }}
                  >
                    Applications
                  </SmallHeading>
                  <SmallHeading sx={{ color: '#3d3d3d' }}>
                    {selectedApp.title}
                  </SmallHeading>
                </Breadcrumbs>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mt: 4,
                    // mb: 3,
                  }}
                >
                  <CardMedia
                    component="img"
                    image={selectedApp.smallLogo}
                    alt={`${selectedApp.title} logo`}
                    sx={{
                      height: '60px',
                      width: '60px',
                    }}
                  />
                  <ResponsiveHeading>{selectedApp.title}</ResponsiveHeading>
                </Box>
              </Box>
            )}
            <Box>
              <Box marginTop={4}>
                {!selectedApp ? (
                  <Box>
                    <Box>
                      <Box marginTop={6}>
                        <SmallHeading>Application Suite</SmallHeading>
                        <Divider
                          sx={{
                            mt: 2,
                            marginBottom: 5,
                            height: '1px',
                            backgroundColor: '#ececec',
                          }}
                        />
                        <Box display="flex" flexWrap="wrap" gap={3}>
                          {appsWithPinnedStatus?.map((app, index) => (
                            <Box
                              key={app.appKey || `${app.title}-${index}`} // Use app.appKey or combine title and index for uniqueness
                              sx={{ width: '275px', height: '240px' }}
                            >
                              <Box>
                                <Card
                                  elevation={3}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                    padding: 1,
                                    backgroundColor:
                                      multiColors[index % multiColors.length],
                                    borderRadius: '35px',
                                    height: '240px',
                                    boxShadow: 'none',
                                    width: '100%',
                                    border: '1px solid #d9d9d9',
                                    position: 'relative',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      marginTop: 2,
                                      marginLeft: 2,
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      image={app.smallLogo}
                                      alt={`${app.title} logo`}
                                      sx={{
                                        height: '40px',
                                        width: '40px',
                                        marginBottom: 1,
                                      }}
                                    />
                                    <CardContent
                                      sx={{ padding: 0, marginTop: '16px' }}
                                    >
                                      <ResponsiveHeading
                                        sx={{
                                          fontSize: '20px !important',
                                        }}
                                      >
                                        {app.title}
                                      </ResponsiveHeading>
                                    </CardContent>
                                  </Box>

                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      top: '20px',
                                      right: '20px',
                                      borderRadius: '50%',
                                      padding: '6px',
                                      fontSize: '6px',
                                      backgroundColor: app.pinned
                                        ? '#0052cc'
                                        : '#ffffff',
                                      color: app.pinned ? 'white' : '#757575',
                                      ':hover': {
                                        backgroundColor: app.pinned
                                          ? '#0041a8'
                                          : '#f0f0f0',
                                        color: app.pinned ? 'white' : '#333',
                                      },
                                      transition:
                                        'background-color 0.3s ease, box-shadow 0.3s ease',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handlePinnedApp(app.title);
                                    }}
                                  >
                                    <PushPinIcon
                                      sx={{
                                        fontSize: '18px',
                                        transform: app.pinned
                                          ? 'rotate(-45deg)'
                                          : 'none',
                                        transition: 'transform 0.3s ease',
                                      }}
                                    />
                                  </IconButton>

                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      bottom: '10px',
                                      right: '15px',
                                      backgroundColor: '#ffffff',
                                      borderRadius: '50%',
                                      padding: '16px',
                                      fontSize: '16px',
                                      ':hover': {
                                        backgroundColor: '#0052cc',
                                        color: 'white',
                                      },
                                    }}
                                    onClick={() => handleArrowClick(app.title)}
                                  >
                                    <ArrowForwardIcon />
                                  </IconButton>
                                </Card>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <SingletonApplications
                        appName="Singleton"
                        displayedApps={displayedApps}
                      />
                    </Box>
                  </Box>
                ) : (
                  <SingletonApplications
                    appName={selectedApp}
                    displayedApps={groupApps}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}
