import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getCookie } from '../../../../../utils/utility';
import {
  approveButtonStyles,
  revokeButtonStyles,
} from '../../../../../styles/button';
import { handleDeviceAction } from '../../../../../Redux/Slices/Application/DeviceManagement/deviceManagementHandler';
import { AppDispatch } from '../../../../../Redux/store';

interface DeviceDetails {
  os: string | null;
  ip: string | null;
  browser: string | null;
}
interface UserDetail {
  username: string;
  mac_address: string;
  approval_request_time: string;
  device_validity: string;
  permanent_access: boolean;
  status: string;
  access: boolean;
  device_details: DeviceDetails;
}
interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
  data: UserDetail | null;
  commonID: string;
  tenantID: string;
  app: string;
}
const dialogStyles = {
  borderRadius: '0.75rem',
  boxShadow: '0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1)',
};

const containerStyles = {
  padding: '1.5rem',
  fontFamily: 'Arial, sans-serif',
  border: '0.063rem solid #d7d7d7',
  borderRadius: '0.625rem',
  backgroundColor: '#fafafa',
};

const keyValueStyle: React.CSSProperties = {
  display: 'flex',
  padding: '0.5rem 0',
  borderBottom: '0.063rem solid #ddd',
};

const keyStyle = {
  fontWeight: '500',
  color: '#333',
  width: '40%',
};

const valueStyle = {
  color: '#555',
};

export async function handleDeleteUserDetails(
  commonID: string,
  tenantID: string,
  app: string,
  username: string,
  successMessage: string,
) {
  const token = await getCookie('token');
  const payloadData = {
    _id: commonID,
    tenant_id: tenantID,
    appName: app,
    username,
  };
  try {
    await axios.post(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/api/casb/delete/device-approval`,
      payloadData,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    toast.success(successMessage);
    return true;
  } catch (error) {
    toast.error('Unable to send the delete request.');
    return false;
  }
}

function PopupDialog({
  open,
  onClose,
  data,
  commonID,
  tenantID,
  app,
}: PopupDialogProps) {
  if (!data) {
    return null;
  }
  const dispatch = useDispatch<AppDispatch>();
  const isApproved = data.status === 'pending' || data.status === 'denied';
  const columnKeys: Record<string, string> = {
    os: 'Operating System',
    ip: 'IP address',
    browser: 'Browser',
  };
  const handleApproveAccess = () => {
    dispatch(
      handleDeviceAction({
        commonID,
        tenantID,
        app,
        username: data.username,
        access: true,
        macAddress: data.mac_address,
        permanent_access: true,
        successMessage: 'The Device Access Has Been Approved.',
      }),
    ).then(onClose);
  };
  const handleDenyAccess = () => {
    dispatch(
      handleDeviceAction({
        commonID,
        tenantID,
        app,
        username: data.username,
        access: false,
        macAddress: data.mac_address,
        permanent_access: false,
        successMessage: 'The Device Access Has Been Denied.',
      }),
    ).then(onClose);
  };
  const handleRevokeAccess = () => {
    dispatch(
      handleDeviceAction({
        commonID,
        tenantID,
        app,
        username: data.username,
        access: false,
        macAddress: data.mac_address,
        permanent_access: true,
        successMessage: 'The Device Access Has Been Revoked.',
      }),
    ).then(onClose);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={dialogStyles}
    >
      <DialogTitle
        sx={{ m: 0, p: 2, borderBottom: '0.063rem solid #d7d7d7' }}
        id="customized-dialog-title"
      >
        {isApproved ? 'Device Access Has Been Revoked' : 'Device Access Has Been Approved'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {!isApproved && (
          <Typography fontSize={20} fontWeight={500}>
            Do you really want to revoke the access?
          </Typography>
        )}
        {isApproved && data && (
          <>
            <Typography
              fontSize={20}
              fontWeight={500}
              sx={{ marginBottom: '0.625rem' }}
            >
              Device Information
            </Typography>
            <Box sx={containerStyles}>
              <Box sx={keyValueStyle}>
                <Typography variant="body1" sx={keyStyle}>
                  Username
                </Typography>
                <Typography variant="body1" sx={valueStyle}>
                  {data?.username}
                </Typography>
              </Box>
              <Box sx={keyValueStyle}>
                <Typography variant="body1" sx={keyStyle}>
                  MAC Address
                </Typography>
                <Typography variant="body1" sx={valueStyle}>
                  {data?.mac_address}
                </Typography>
              </Box>
              {Object.entries(data?.device_details || {}).map(
                ([key, value]) => {
                  const displayKey = columnKeys[key] || key;
                  return (
                    <Box key={key} sx={keyValueStyle}>
                      <Typography variant="body1" sx={keyStyle}>
                        {displayKey}
                      </Typography>
                      <Typography variant="body1" sx={valueStyle}>
                        {value || 'N/A'}
                      </Typography>
                    </Box>
                  );
                },
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '0.625rem 1.875rem 1.25rem' }}>
        <Button
          variant="text"
          sx={!isApproved ? revokeButtonStyles : approveButtonStyles}
          onClick={!isApproved ? handleRevokeAccess : handleApproveAccess}
        >
          {!isApproved ? 'Revoke Access' : 'Approve Access'}
        </Button>
        {isApproved && (
          <Button
            variant="text"
            sx={revokeButtonStyles}
            onClick={handleDenyAccess}
          >
            Deny Access
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default PopupDialog;
