import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Box,
  Grid,
  Fade,
  // Chip,
  // OutlinedInput,
  // Typography,
  // Slider,
  useMediaQuery,
  // Checkbox,
  // ListItemText,
  // Select,
  // FormControl,
  // MenuItem,
  Button,
} from '@mui/material';
// import { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {
  PieChart, Pie, Cell, Tooltip,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import MainLayout from '../../components/MainLayout';
import { AppDispatch, RootState } from '../../Redux/store';
// import { apps } from '../Application/app';
// import { ResponsiveImage } from '../../styles/app-logo';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { ResponsiveHeading } from '../../styles/heading';
import { SmallHeading } from '../../styles/smallHeading';
import { ResponsiveSubHeading } from '../../styles/subHeading';
// import Logo from '../assets/images/miniorange-shortlogo.webp';
import {
  getMostRestrictedUsersData,
  getRestrictions,
  getSeverityInsights,
} from '../../Redux/Slices/Auditing/auditingHandler';
import AuditingCommonTable from '../../components/Table/AuditingCommonTable';

// const MultipleSelectCheckmarks: React.FC<{ selectedNames: string[], setSelectedNames: React.Dispatch<React.SetStateAction<string[]>> }> = ({ selectedNames, setSelectedNames }) => {
//   const handleChange = (event: SelectChangeEvent<typeof selectedNames>) => {
//     const { target: { value } } = event;
//     setSelectedNames(typeof value === 'string' ? value.split(',') : value);
//   };

//   return (
//     <FormControl sx={{ minWidth: 150, backgroundColor: '#F5F6FA', borderRadius: '0.375rem' }}>
//   <Select
//     id="demo-multiple-checkbox"
//     multiple
//     value={selectedNames}
//     onChange={handleChange}
//     renderValue={(selected) => selected.join(', ')}
//     MenuProps={{
//       PaperProps: {
//         style: {
//           width: '10%',
//         },
//       },
//     }}
//     sx={{
//       height: '35px',
//       backgroundColor: '#fafafa',
//       border: 'none',
//       '& .MuiOutlinedInput-notchedOutline': {
//         border: 'none',
//       },
//       '& .MuiSelect-icon': {
//         color: 'black',
//       },
//       '&:hover .MuiOutlinedInput-notchedOutline': {
//         border: 'none',
//       },
//       '&:focus .MuiOutlinedInput-notchedOutline': {
//         border: 'none',
//       },
//       '&:hover': {
//         backgroundColor: '#fafafa',
//       },
//       '&.Mui-focused': {
//         backgroundColor: '#fafafa',
//         '& .MuiOutlinedInput-notchedOutline': {
//           border: 'none',
//         },
//       },
//     }}
//   >
//     {names.map((name) => (
//       <MenuItem
//         key={name}
//         value={name}
//         sx={{
//           height: '30px',
//           minWidth: '20%',
//         }}
//       >
//         <Checkbox checked={selectedNames.includes(name)} />
//         <ListItemText primary={name} />
//       </MenuItem>
//     ))}
//   </Select>
// </FormControl>
//   );
// };

// interface DailyData {
//   date: string;
//   users: number;
// }
// interface SeverityInsight {
//   name: string;
//   value: number;
// }
interface SeverityInsights {
  [key: string]: { name: string; value: number }[];
}

const severityColors = {
  Low: '#0052cc',
  Medium: '#fcdd24',
  High: '#ec0c0c',
};

function CustomTooltip({
  payload,
  isDataEmpty,
}: {
  payload: any;
  isDataEmpty: boolean;
}) {
  if (isDataEmpty) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '12px 16px',
          borderRadius: '10px',
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
          maxWidth: '300px',
          fontSize: '16px',
          color: '#333',
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 'bold',
            color: '#e74c3c',
            fontSize: '18px',
          }}
        >
          No Data
        </p>
      </div>
    );
  }
  if (payload && payload.length) {
    const { name, value } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '12px 16px',
          borderRadius: '10px',
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
          maxWidth: '300px',
          fontSize: '16px',
          color: '#333',
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 'bold',
            color:
              severityColors[name as keyof typeof severityColors] || '#333',
            fontSize: '18px',
          }}
        >
          {name}
        </p>
        <p
          style={{
            margin: 0,
            color: '#555',
            fontSize: '14px',
          }}
        >
          Value:
          {' '}
          {value}
        </p>
      </div>
    );
  }
  return null;
}

function ApplicationInsights() {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('All Time');
  const [totalCount, setTotalCount] = useState(0);

  const severityInsights: SeverityInsights = useSelector(
    (state: RootState) => state.auditingDashboard.severityInsights || {},
  );

  const [filteredData, setFilteredData] = useState(severityInsights?.ALL_TIME);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.between(0, 600));
  const isSm = useMediaQuery(theme.breakpoints.between(600, 900));
  const isMd = useMediaQuery(theme.breakpoints.between(900, 1000));
  // const isMd1 = useMediaQuery(theme.breakpoints.between(1000, 1100));
  const isMd2 = useMediaQuery(theme.breakpoints.between(1100, 1200));
  const isLg = useMediaQuery(theme.breakpoints.between(1200, 1300));
  const isLg1 = useMediaQuery(theme.breakpoints.between(1300, 1400));
  const isXl = useMediaQuery(theme.breakpoints.between(1400, 1500));
  const isXl1 = useMediaQuery(theme.breakpoints.between(1500, 1600));
  const isXl2 = useMediaQuery(theme.breakpoints.between(1600, 1700));
  const isXl3 = useMediaQuery(theme.breakpoints.up(1700));

  const chartConfig = (() => {
    if (isXs) {
      return {
        width: 375,
        height: 320,
        innerRadius: 87,
        outerRadius: 120,
      };
    }
    if (isSm) {
      return {
        width: 385,
        height: 325,
        innerRadius: 90,
        outerRadius: 130,
      };
    }
    if (isMd) {
      return {
        width: 390,
        height: 315,
        innerRadius: 92,
        outerRadius: 135,
      };
    }
    if (isLg1) {
      return {
        width: 230,
        height: 240,
        innerRadius: 70,
        outerRadius: 110,
      };
    }
    if (isLg) {
      return {
        width: 230,
        height: 235,
        innerRadius: 70,
        outerRadius: 110,
      };
    }
    if (isXl) {
      return {
        width: 230,
        height: 220,
        innerRadius: 75,
        outerRadius: 114,
      };
    }
    if (isMd2) {
      return {
        width: 230,
        height: 233,
        innerRadius: 70,
        outerRadius: 110,
      };
    }
    if (isXl1) {
      return {
        width: 430,
        height: 300,
        innerRadius: 100,
        outerRadius: 150,
      };
    }
    if (isXl2) {
      return {
        width: 440,
        height: 340,
        innerRadius: 100,
        outerRadius: 150,
      };
    }
    if (isXl3) {
      return {
        width: 450,
        height: 350,
        innerRadius: 100,
        outerRadius: 150,
      };
    }
    return {
      width: 450,
      height: 400,
      innerRadius: 100,
      outerRadius: 150,
    };
  })();
  useEffect(() => {
    if (severityInsights) {
      setFilteredData(severityInsights?.ALL_TIME || []);
    }
  }, [severityInsights]);

  useEffect(() => {
    dispatch(getSeverityInsights());
  }, []);
  const isLegendVisible = !(isLg || isMd || isMd2);

  const recalculateTotalCount = (data: any[]) => data.reduce((acc, curr) => acc + curr.value, 0);

  const handleDuration = (period: string) => {
    if (period === 'All Time') {
      setSelectedTimePeriod('All Time');
    } else {
      setSelectedTimePeriod(period);
    }
    if (severityInsights) {
      if (period === 'All Time') {
        const dataForPeriod = severityInsights?.ALL_TIME || [];
        setFilteredData(severityInsights?.ALL_TIME || []);
        setTotalCount(recalculateTotalCount(dataForPeriod));
      } else {
        const dataForPeriod = severityInsights?.[period] || [];
        setFilteredData(severityInsights?.[period] || []);
        setTotalCount(recalculateTotalCount(dataForPeriod));
      }
    }
  };
  useEffect(() => {
    if (severityInsights) {
      const initialData = severityInsights?.ALL_TIME || [];
      setFilteredData(initialData);
      setTotalCount(recalculateTotalCount(initialData));
    }
  }, [severityInsights]);

  const isDataEmpty = !filteredData || filteredData.length === 0;
  const fallbackColor = '#E0E0E0';
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        mt={1.5}
        sx={{
          // p: 1.5,
          p: {
            xxl: 1.5,
            xl2: 1.5,
            xl1: 1.5,
            xl: '10.6px',
            lg1: '6px',
            lg: '5px',
            isMd2: '1px',
          },
          borderRadius: '2rem',
          gap: {
            xxl: '1rem',
            xl2: '1rem',
            xl1: '1rem',
            xl: '0.3rem',
            lg1: '0.8rem',
            lg: '0.3rem',
            md2: '0.0rem',
            md1: '0.8rem',
            md: '0.8rem',
          },
          backgroundColor: '#F5F6FA',
          flexWrap: isXs || isSm ? 'wrap' : 'nowrap',
        }}
      >
        {['1D', '1W', '1M', 'All Time'].map((period) => (
          <Button
            key={period}
            onClick={() => handleDuration(period)}
            sx={{
              borderRadius: '1.5rem',
              color: selectedTimePeriod === period ? '#124e9e' : '#78797f',
              // py: '0.4rem',
              py: {
                xxl: '0.4rem !important',
                xl2: '0.4rem !important',
                xl1: '0.4rem !important',
                xl: '0.2rem !important',
                lg1: '0.2rem !important',
                isMd2: '0.1rem !important',
              },
              px: '1.0rem',
              backgroundColor:
                selectedTimePeriod === period ? '#ffffff' : '#F5F6FA',
              fontSize: {
                xxl: '14px',
                xl2: '14px',
                xl1: '14px',
                xl: '12px',
                lg1: '11px',
                lg: '14px',
                md2: '14px',
                md1: '14px',
                md: '14px',
              },
            }}
          >
            <AllInclusiveIcon
              sx={{
                display:
                  (isXl1 || isXl2 || isXl || isLg1 || isLg || isMd || isMd2)
                  && period === 'All Time'
                    ? 'inline-block'
                    : 'none',
                marginRight: '8px',
              }}
            />
            {period === 'All Time' && (
              <span
                style={{
                  display:
                    isXl1 || isXl2 || isXl || isLg1 || isLg || isMd2
                      ? 'none'
                      : 'inline',
                }}
              >
                {period}
              </span>
            )}
            {period !== 'All Time' && period}
          </Button>
        ))}
      </Box>

      {/* Chart Container */}
      <Box
        position="relative"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          // mt: 2,
          // my : {xl : 2},
          mt: {
            xxl: 0,
            xl2: 0,
            xl1: 0,
            xl: 2,
            lg1: 0,
            lg: 0,
            md2: 0,
            md1: 0,
            md: 0,
          },
          pb: {
            xxl: 0,
            xl2: 0,
            xl1: 0,
            xl: 2,
            lg1: 0,
            lg: 0,
            md2: 0,
            md1: 0,
            md: 0,
          },
          borderBottom:
            isLegendVisible && totalCount !== 0 && !isDataEmpty
              ? '2px solid lightgrey'
              : 'none',
          transition: 'all 0.3s ease',
          backgroundColor: isXl ? 'white' : '#FFFFF',
        }}
      >
        {/* Pie Chart */}
        <PieChart width={chartConfig.width} height={chartConfig.height}>
          <Pie
            data={
              isDataEmpty ? [{ severity: 'No Data', value: 1 }] : filteredData
            }
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={chartConfig.innerRadius}
            outerRadius={chartConfig.outerRadius}
            strokeWidth={8}
            cornerRadius={10}
          >
            {filteredData?.map((entry, index) => (
              <Cell
                key={`cell-${index + 1}`}
                fill={
                  severityColors[entry.name as keyof typeof severityColors]
                  || fallbackColor
                }
              />
            ))}
          </Pie>
          <Tooltip
            wrapperStyle={{
              zIndex: 1000,
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
            }}
            content={
              <CustomTooltip payload={filteredData} isDataEmpty={isDataEmpty} />
            }
          />
          {/* /> */}
        </PieChart>

        {/* Centered Text */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
        >
          <p
            style={{
              margin: 0,
              color: '#7f829b',
            }}
          >
            {selectedTimePeriod === 'All Time'
              ? 'ALL TIME'
              : `LAST ${selectedTimePeriod}`}
          </p>
          <p
            style={{
              margin: 0,
              color: '#7f829b',
              fontSize: isXs ? '14px' : '17px',
            }}
          >
            TOTAL COUNT
          </p>
          <h3
            style={{
              margin: 0,
              color: '#373b47',
              fontSize: isXs ? '20px' : '24px',
            }}
          >
            {totalCount}
          </h3>
        </Box>
      </Box>

      {/* Legend */}
      {!(isLg || isMd || isMd2) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          // mt={1}
          sx={{
            mt: {
              xxl: 1,
              xl2: 1,
              xl1: 1,
              xl: 1,
              lg1: 1,
              lg: 1,
              md2: 1,
              md1: 1,
              md: 1,
            },
            gap: {
              xxl: 3,
              xl2: 3,
              xl1: 3,
              xl: '11px',
              lg1: '6px',
              lg: 3,
              md2: 3,
              md1: 3,
              md: 3,
            },
          }}
        >
          {filteredData?.map((item) => (
            <Box key={item.name} display="flex" alignItems="center" gap={1}>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor:
                    severityColors[item.name as keyof typeof severityColors],
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
              <ResponsiveTypography
                variant="body2"
                sx={{
                  // fontSize: '14px',
                  fontSize: {
                    isXl3: '14px',
                    isXl2: '14px',
                    isXl1: '14px',
                    isXl: '14px',
                    isLg: '120px',
                    isMd2: '14px',
                  },

                  color: '#7f829b',
                  lineHeight: '18px',
                }}
              >
                {item.name}
              </ResponsiveTypography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

interface ChartData {
  series: { name: string; data: any[] }[];
  categories: string[];
}

export const renderNoDataMessage = () => (
  <Box
    sx={{
      width: '100%',
      height: { xxl: '70%', xl: '60%' },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ResponsiveHeading color="text.secondary" sx={{ fontWeight: 500 }}>
      No Data Available
    </ResponsiveHeading>
  </Box>
);

function LineChart() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getRestrictions());
  }, []);

  const { totalRestrictions } = useSelector(
    (state: RootState) => state.auditingDashboard,
  );

  const [chartData, setChartData] = useState<ChartData>({
    series: [],
    categories: [],
  });

  const [yAxisMax, setYAxisMax] = useState<number>(100);

  useEffect(() => {
    if (totalRestrictions && totalRestrictions.length > 0) {
      const categories = totalRestrictions.map((item) => {
        const date = new Date(item.day);
        return date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        });
      });

      const series = [
        {
          name: 'IP Based',
          data: totalRestrictions.map((item) => item.IP_Restriction),
        },
        {
          name: 'Location Based',
          data: totalRestrictions.map((item) => item.Location_Restrictions),
        },
        {
          name: 'Time Based',
          data: totalRestrictions.map((item) => item.Time_Restriction),
        },
        {
          name: 'Blocked Apps',
          data: totalRestrictions.map((item) => item.Blocked_Apps),
        },
        {
          name: 'Session Based',
          data: totalRestrictions.map(
            (item) => item.Session_Access_Restriction,
          ),
        },
        {
          name: 'File Based',
          data: totalRestrictions.map(
            (item) => item.File_Format_Access_Restriction,
          ),
        },
      ];

      const allDataPoints = series.flatMap((s) => s.data);
      const calculatedMax = Math.max(...allDataPoints);
      const buffer = 10;
      setYAxisMax(calculatedMax + buffer);

      setChartData({ series, categories });
    } else {
      setChartData({ series: [], categories: [] });
    }
  }, [totalRestrictions]);

  const colorArray = [
    '#0052CC',
    '#97DB7A',
    '#FFAB00',
    '#FF5630',
    '#FF6F61',
    '#FF6347',
    '#32CD32',
    '#FFD700',
  ];

  const options = {
    chart: {
      type: 'line',
      stacked: false,
      zoom: {
        enabled: true,
      },
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        offsetY: -30,
        autoSelected: 'zoom',
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          colors: 'rgba(97, 94, 131, 1)',
          fontSize: '14px',
          fontStyle: 'SF Pro',
          fontWeight: '510',
          textAlign: 'center',
          lineHeight: '18px',
        },
        offsetX: 10,
        offsetY: 5,
      },
    },
    markers: {
      size: 3,
      colors: colorArray,
      strokeColor: colorArray,
      strokeWidth: 3,
    },
    yaxis: {
      show: true,
      opposite: true,
      min: 0,
      max: yAxisMax,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#615E83',
          fontSize: '15px',
          fontStyle: 'SF Pro',
          fontWeight: 'bold',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: colorArray,
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: '14px',
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 20,
        vertical: 8,
      },
      labels: {
        colors: '#615E83',
        style: {
          fontSize: '13px',
          fontWeight: 'bold',
        },
      },
    },
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: chartData.series.length === 0 ? '400px' : '300px',
      }}
    >
      {chartData.series.length === 0 || chartData.categories.length === 0 ? (
        renderNoDataMessage()
      ) : (
        <Chart
          options={options}
          series={chartData.series}
          type="line"
          width="100%"
          height="100%"
        />
      )}
    </Box>
  );
}

function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getMostRestrictedUsersData());
  }, []);

  const { restrictedUsers } = useSelector(
    (state: RootState) => state.auditingDashboard,
  );
  const restrictedUsersArray = Object.values(restrictedUsers);
  return (
    <MainLayout>
      <Grid container padding={3}>
        <Grid item xs={12}>
          <Box marginBottom="1rem" marginLeft={5} marginRight={2}>
            <ResponsiveHeading>Dashboard</ResponsiveHeading>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sx={{ color: 'transparent' }}>
          <ResponsiveHeading>.</ResponsiveHeading>
        </Grid> */}

        <Grid container spacing={2} marginLeft={3} marginRight={3}>
          {/* this is left table */}
          <Grid item xs={12} md={8}>
            <Fade in timeout={1000}>
              <Box
                flexDirection="column"
                sx={{
                  width: '100%',
                  height: {
                    md: '51vh',
                    xl: '40vh',
                    lg1: '39vh',
                    lg: '38vh',
                  },
                  borderRadius: '0.625rem',
                  border: '0.063rem solid #D9D9D9',
                  paddingY: '1rem',
                  paddingX: {
                    xs: '1rem',
                    sm: '1.5rem',
                    md: '2rem',
                  },
                  boxShadow: '0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)',
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <SmallHeading>Restriction (Last 7 days)</SmallHeading>
                  <Box>
                    {/* <MultipleSelectCheckmarks
                      selectedNames={selectedNames}
                      setSelectedNames={setSelectedNames}
                    /> */}
                  </Box>
                </Box>
                <ResponsiveSubHeading pt={{ xl: '0.5rem' }}>
                  Restrictions Based off Configured Apps.
                </ResponsiveSubHeading>
                <LineChart />
              </Box>
            </Fade>

            <Fade in timeout={1000}>
              <Box
                flexDirection="column"
                borderRadius="0.625rem"
                border="0.063rem solid #D9D9D9"
                height={{
                  md: '11.25rem',
                  lg: '15rem',
                  xl2: '19rem',
                  xl1: '18rem',
                  xl: '18rem',
                  xxl: '23rem',
                  lg1: '16.5rem',
                }}
                mt="0.8rem"
                p={{
                  xl: '0.7rem',
                  md: '0.5rem',
                  lg: '0.75rem',
                  xl2: '0.9rem',
                  xl1: '0.6rem',
                }}
                overflow={{
                  xl2: 'hidden',
                  xl1: 'hidden',
                  xl: 'hidden',
                  lg1: 'auto',
                  lg: 'auto',
                  xxl: 'hidden',
                }}
                boxShadow="0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)"
                flexGrow="1"
              >
                <SmallHeading>Top 5 Critical Users (Last 1 Month)</SmallHeading>
                <Box flexGrow={1}>
                  <AuditingCommonTable
                    data={restrictedUsersArray}
                    columns={[
                      { field: 'user', headerName: 'User' },
                      {
                        field: 'restriction_count',
                        headerName: 'Restriction Count',
                      },
                      { field: 'ip_addresses', headerName: 'IP addresses' },
                      // { field: 'policies', headerName: 'Policies' },
                      { field: 'casb_app', headerName: 'CASB App' },
                    ]}
                    pages="audit-dashboard"
                  />
                </Box>
              </Box>
            </Fade>
          </Grid>
          {/* this is right table */}
          <Grid item xs={12} md={4}>
            <Fade in timeout={1000}>
              <Box
                display="flex"
                flexDirection="column"
                borderRadius="0.625rem"
                border="0.063rem solid #D9D9D9"
                // height={{ md: '11.25rem', lg: '15rem', xl: '30rem', xl1: '32rem', xxl: '35rem' }}
                // width={{ xxl: '27vw', xl2: '27vw', xl1: '27vw', xl: '23rem' }}
                p={{
                  xl: '1rem',
                  md: '0.5rem',
                  lg: '0.75rem',
                }}
                // marginLeft="0.5rem"
                boxShadow="0rem 0rem 0.625rem rgba(0, 0, 0, 0.05)"
                flexGrow="1"
                marginX={2}
              >
                <SmallHeading>Severity Insights</SmallHeading>
                <ApplicationInsights />
              </Box>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default Dashboard;
