/*eslint-disable*/
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { useSelector , useDispatch } from 'react-redux';
import {
  fetchRestrictionPerDay,
} from '../../Redux/Slices/Auditing/PersistState/preferencesHandler';
import {
  updateLimit,
} from '../../Redux/Slices/Auditing/PersistState/preferences';
import { AppDispatch, RootState } from '../../Redux/store';

interface CommonPaginationProps {
  pages: string;
  // pageCount: number;
  page: number;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  data: any[];
}

export function CommonPagination({
  pages,
  page,
  handlePageChange,
  data,
}: CommonPaginationProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { limit } = useSelector((state: RootState) => state.preferences);

  // Initialize rowsPerPage with the limit from Redux store
  const [rowsPerPage, setRowsPerPage] = useState(limit) ;

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(updateLimit(newRowsPerPage)); 
    dispatch(fetchRestrictionPerDay());   
    setRowsPerPage(newRowsPerPage);     
  };

  const isNextPageDisabled = data.length === 0 || data.length !== limit;
  const isPreviousPageDisabled = page === 1;

  return (
    <Box>
      {!(data.length === 0 && page === 1) && (
        <Box
          sx={{
            mb: 'auto',
            borderTop: '2px solid lightgray',
          }}
        >
          <TablePagination
            component="div"
            count={data.length + 10000}
            labelDisplayedRows={() => `Page ${page} of many`}
            rowsPerPageOptions={[10, 25, 50, 100]}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            nextIconButtonProps={{ disabled: isNextPageDisabled }}
            backIconButtonProps={{ disabled: isPreviousPageDisabled }}
          />
        </Box>
      )}
    </Box>
  );
}

