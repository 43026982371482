import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllPolicies } from './policyHandler';

const initialState = {
  status: 'idle',
  allPolicies: [] as any,
  identifier: '',
  description: '',
  enableIPRestriction: false,
  enableMACaddressEnforcement: false,
  enablewhitelistedMACaddress: false,
  IPAddressToggle: 'allow',
  outOfNetworkToggle: 'allow',
  macEnforcementToggle: false,
  ipAddresses: [''] as string[],
  whitelistedMACaddress: [''] as string[],
  whitelistedMACaddressToggle: false,
  whitelistedIPaddress: [''] as string[],
  enableTimeRestriction: false,
  timeConstraintToggle: 'allow',
  timezone: null,
  startTime: '',
  endTime: '',
  enablePreventDownload: false,
  enableLocationBasedRestriction: false,
  location: [],
  locationRestrictionToggle: 'allow',
  sessionRestrictionToggle: 'd',
  enableFileBasedRestriction: false,
  enableSessionBasedRestriction: false,
  files: [],
  fileRestrictionToggle: 'allow',
  enableClipboardRestriction: false,
  clipboardRestrictions: [],
  sessionDuration: '',
  // Started here
  enableDeviceRestriction: false,
  approverEmail: [''] as string[],
  outOfNetworkMacValidation: false,
  macAddressEnforcementToggle: false,
  _id: '',
  open: false,
  state: 'save',
};

const policySettings = createSlice({
  name: 'policySettings',
  initialState,
  reducers: {
    updateIdentifier: (state, action: PayloadAction<string>) => {
      state.identifier = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    updateEnableIPRestriction: (state, action: PayloadAction<boolean>) => {
      state.enableIPRestriction = action.payload;
    },
    updateEnableMACaddressEnforcement: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.enablewhitelistedMACaddress = action.payload;
    },
    updateIPToggle: (state, action: PayloadAction<string>) => {
      state.IPAddressToggle = action.payload;
    },
    updateOutOfNetworkToggle: (state, action: PayloadAction<string>) => {
      state.outOfNetworkToggle = action.payload;
    },
    updateMacEnforcementToggle: (state, action: PayloadAction<boolean>) => {
      state.macEnforcementToggle = action.payload;
    },
    updateWhitelistedMACaddressToggle: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.whitelistedMACaddressToggle = action.payload;
    },
    updateMacAddressEnforcement: (state, action: PayloadAction<boolean>) => {
      state.macAddressEnforcementToggle = action.payload;
    },
    updateEnableTimeRestriction: (state, action: PayloadAction<boolean>) => {
      state.enableTimeRestriction = action.payload;
    },
    updateTimeToggle: (state, action: PayloadAction<string>) => {
      state.timeConstraintToggle = action.payload;
    },
    updateEnablePreventDownload: (state, action: PayloadAction<boolean>) => {
      state.enablePreventDownload = Boolean(action.payload);
    },
    updateEnableDisableClipboard: (state, action: PayloadAction<boolean>) => {
      state.enableClipboardRestriction = Boolean(action.payload);
    },
    updateClipboardRestrictions: (state, action: PayloadAction<any>) => {
      state.clipboardRestrictions = action.payload;
    },
    updateLocationBasedrestriction: (state, action: PayloadAction<boolean>) => {
      state.enableLocationBasedRestriction = Boolean(action.payload);
    },
    updateFileBasedrestriction: (state, action: PayloadAction<boolean>) => {
      state.enableFileBasedRestriction = Boolean(action.payload);
    },
    updateSessionBasedrestriction: (state, action: PayloadAction<boolean>) => {
      state.enableSessionBasedRestriction = Boolean(action.payload);
    },
    updateIPAddressList: (state, action: PayloadAction<any>) => {
      state.ipAddresses = action.payload;
    },
    updateApproverEmail: (state, action: PayloadAction<any>) => {
      state.approverEmail = action.payload;
    },
    updateTimeZone: (state, action: PayloadAction<any>) => {
      state.timezone = action.payload;
    },
    updateStartTime: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    updateEndTime: (state, action: PayloadAction<string>) => {
      state.endTime = action.payload;
    },
    updateLocationToggle: (state, action: PayloadAction<string>) => {
      state.locationRestrictionToggle = action.payload;
    },
    updateSessionToggle: (state, action: PayloadAction<string>) => {
      state.sessionRestrictionToggle = action.payload;
    },
    updateSessionDuration: (state, action: PayloadAction<string>) => {
      state.sessionDuration = action.payload;
    },
    updateFileToggle: (state, action: PayloadAction<string>) => {
      state.fileRestrictionToggle = action.payload;
    },
    updateLocationList: (state, action: PayloadAction<any>) => {
      state.location = action.payload;
    },
    updateFileList: (state, action: PayloadAction<any>) => {
      state.files = action.payload;
    },
    removeIP: (state, action: PayloadAction<number>) => {
      state.ipAddresses.splice(action.payload, 1);
    },
    removeWhitelistedIPaddresses: (state, action: PayloadAction<number>) => {
      state.whitelistedIPaddress.splice(action.payload, 1);
    },
    removeWhitelistedMACaddress: (state, action: PayloadAction<number>) => {
      state.whitelistedMACaddress.splice(action.payload, 1);
    },
    removeApproverEmail: (state, action: PayloadAction<number>) => {
      state.approverEmail.splice(action.payload, 1);
    },
    updateIPList: (state, action: PayloadAction<any>) => {
      state.ipAddresses[action.payload.index] = action.payload.value;
    },
    updatewhitelistedIPList: (state, action: PayloadAction<any>) => {
      state.whitelistedIPaddress[action.payload.index] = action.payload.value;
    },
    updatewhitelistedMACaddressList: (state, action: PayloadAction<any>) => {
      state.whitelistedMACaddress[action.payload.index] = action.payload.value;
    },
    updateApproverEmailList: (state, action: PayloadAction<any>) => {
      state.approverEmail[action.payload.index] = action.payload.value;
    },
    addApproverEmail: (state) => {
      state.approverEmail = [...state.approverEmail, ''];
    },
    addIPAddress: (state) => {
      state.ipAddresses = [...state.ipAddresses, ''];
    },
    addwhitelistedIPaddress: (state) => {
      state.whitelistedIPaddress = [...state.whitelistedIPaddress, ''];
    },
    addWhitelistedMACaddress: (state) => {
      state.whitelistedMACaddress = [...state.whitelistedMACaddress, ''];
    },
    clearWhitelistedMACaddressList: (state) => {
      state.whitelistedMACaddress = [];
    },
    clearWhitelistedIPaddressList: (state) => {
      state.whitelistedIPaddress = [];
    },
    updateOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    updateDeviceBasedRestriction: (state, action: PayloadAction<boolean>) => {
      state.enableDeviceRestriction = Boolean(action.payload);
    },
    updateOutOfNetworkMacValidation: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.outOfNetworkMacValidation = Boolean(action.payload);
    },
    updatePolicyState: (state, action: PayloadAction<any>) => {
      state.identifier = action.payload.identifier;
      state.description = action.payload.description;
      state.enableIPRestriction = action.payload.ip_based_restriction_feature.enable;

      state.enableMACaddressEnforcement = action.payload.device_based_restriction_feature
        ?.enforced_device_restriction?.enable ?? false;
      state.enablewhitelistedMACaddress = action.payload.device_based_restriction_feature
        ?.whitelisted_device_restriction?.enable ?? false;
      state.IPAddressToggle = action.payload.ip_based_restriction_feature.access_control;
      state.outOfNetworkToggle = action.payload.device_based_restriction_feature
        ?.out_of_network_device_restriction?.enable ?? false;
      state.macEnforcementToggle = action.payload.device_based_restriction_feature
        ?.enforced_device_restriction?.enable ?? false;
      state.whitelistedMACaddressToggle = action.payload.device_based_restriction_feature
        ?.whitelisted_device_restriction?.enable ?? false;
      state.ipAddresses = action.payload.ip_based_restriction_feature.ip_address_list;
      state.whitelistedIPaddress = action.payload.device_based_restriction_feature
        ?.out_of_network_device_restriction?.whitelisted_ip_address_list
        ?? [];
      state.whitelistedMACaddress = action.payload.device_based_restriction_feature
        ?.whitelisted_device_restriction?.whitelisted_mac_address_list ?? [];
      state.approverEmail = action.payload.device_based_restriction_feature?.approver_email_list
        ?? [];

      // Additional checks for time, file, location, and session restrictions
      state.enableTimeRestriction = action.payload.time_based_restriction_feature.enable;
      state.timeConstraintToggle = action.payload.time_based_restriction_feature.access_control;
      state.timezone = action.payload.time_based_restriction_feature.time_zone;
      state.startTime = action.payload.time_based_restriction_feature.start_time;
      state.endTime = action.payload.time_based_restriction_feature.end_time;
      state.enablePreventDownload = action.payload.file_restriction_feature.enable;
      state.enableLocationBasedRestriction = action.payload.location_based_restriction.enable;
      state.location = action.payload.location_based_restriction.location_list;
      state.locationRestrictionToggle = action.payload.location_based_restriction.access_control;
      state.sessionRestrictionToggle = action.payload.session_based_restriction?.access_control;
      state.enableFileBasedRestriction = action.payload.file_based_restriction.enable;
      state.enableSessionBasedRestriction = action.payload.session_based_restriction?.enable;
      state.sessionDuration = action.payload.session_based_restriction?.session_duration;
      state.files = action.payload.file_based_restriction.file_list;
      state.fileRestrictionToggle = action.payload.file_based_restriction.access_control;
      state.enableClipboardRestriction = action.payload.clipboard_restriction_feature.enable;
      state.clipboardRestrictions = action.payload.clipboard_restriction_feature.clipboard_restriction;
      state.enableDeviceRestriction = action.payload.device_based_restriction_feature?.enable;
      state.outOfNetworkMacValidation = action.payload.device_based_restriction_feature
        ?.out_of_network_device_restriction?.enable ?? false;
      state._id = action.payload._id;
      state.state = 'update';
    },

    resetPolicyDetails: (state) => {
      state.identifier = '';
      state.description = '';
      state.enableIPRestriction = false;
      state.enableMACaddressEnforcement = true;
      state.IPAddressToggle = 'allow';
      state.outOfNetworkToggle = 'allow';
      state.whitelistedMACaddressToggle = false;
      state.ipAddresses = [];
      state.whitelistedIPaddress = [];
      state.whitelistedMACaddress = [];
      state.approverEmail = [];
      state.enableTimeRestriction = false;
      state.timeConstraintToggle = 'allow';
      state.timezone = null;
      state.startTime = '';
      state.endTime = '';
      state.enablePreventDownload = false;
      state.enableLocationBasedRestriction = false;
      state.location = [];
      state.locationRestrictionToggle = 'allow';
      state.sessionRestrictionToggle = 'd';
      state.sessionDuration = '';
      state.enableFileBasedRestriction = false;
      state.enableSessionBasedRestriction = false;
      state.files = [];
      state.fileRestrictionToggle = 'allow';
      state.enableClipboardRestriction = false;
      state.clipboardRestrictions = [];
      state.enableDeviceRestriction = false;
      state._id = '';
      state.state = 'save';
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllPolicies.fulfilled, (state, action) => {
      state.allPolicies = action.payload;
      state.status = 'succeeded';
    });
  },
});

export const {
  updateIdentifier,
  updateDescription,
  updateEnableIPRestriction,
  updateEnableTimeRestriction,
  updateEnablePreventDownload,
  updateFileBasedrestriction,
  updateSessionBasedrestriction,
  updateDeviceBasedRestriction,
  updateEnableDisableClipboard,
  updateClipboardRestrictions,
  updateLocationBasedrestriction,
  updateMacAddressEnforcement,
  updateIPToggle,
  updateTimeToggle,
  updateIPAddressList,
  updatewhitelistedIPList,
  addwhitelistedIPaddress,
  updateTimeZone,
  updateStartTime,
  updateEndTime,
  updateLocationToggle,
  updateSessionToggle,
  updateSessionDuration,
  updateLocationList,
  updateFileList,
  resetPolicyDetails,
  updateFileToggle,
  updatePolicyState,
  removeIP,
  updateIPList,
  addIPAddress,
  updateOpen,
  addApproverEmail,
  updateApproverEmail,
  removeApproverEmail,
  updateOutOfNetworkMacValidation,
  removeWhitelistedIPaddresses,
  removeWhitelistedMACaddress,
  updatewhitelistedMACaddressList,
  addWhitelistedMACaddress,
  updateEnableMACaddressEnforcement,
  updateOutOfNetworkToggle,
  updateApproverEmailList,
  updateMacEnforcementToggle,
  updateWhitelistedMACaddressToggle,
  clearWhitelistedMACaddressList,
  clearWhitelistedIPaddressList,
} = policySettings.actions;
export default policySettings;
