import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Pagination,
  Popover,
  Tooltip,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-hot-toast';
import CachedIcon from '@mui/icons-material/Cached';
import { AppDispatch, RootState } from '../../../../Redux/store';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import PopupDialog from './Common/popupDialog';
import { StyledTextField } from '../../../../styles/textField';
import { buttonIconStyles } from '../../../../styles/icon';
import { deleteUserDetails } from '../../../../Redux/Slices/Application/DeviceManagement/deviceManagementHandler';
import { getApplicationStatus } from '../../../../Redux/Slices/Application/applicationHandler';
import { updateEditPageName } from '../../../../Redux/Slices/Application/appSlice';

interface DeviceDetails {
  os: string | null;
  ip: string | null;
  browser: string | null;
}

interface UserDetail {
  username: string;
  mac_address: string;
  approval_request_time: string;
  device_validity: string;
  permanent_access: boolean;
  status: string;
  access: boolean;
  device_details: DeviceDetails;
}
function DeviceManagement() {
  const { editScreen, app } = useSelector(
    (state: RootState) => state.application,
  );
  const { commonID, tenantID } = useSelector(
    (state: RootState) => state.basicSettings,
  );
  const dispatch = useDispatch<AppDispatch>();

  const { userDetails } = useSelector(
    (state: RootState) => state.basicSettings.deviceManagement,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;
  const columnLabels: string[] = [
    'Username',
    'IP address',
    'MAC address',
    'Operating System',
    'Approval Request Time',
    'Status',
    'Action',
  ];
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [
    selectedRowForPopover,
    setSelectedRowForPopover,
  ] = useState<UserDetail | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    row: UserDetail,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowForPopover(row);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedRowForPopover(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleClose = () => {
    setOpen(false);
    handlePopoverClose();
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };
    return date.toLocaleString(undefined, options);
  };

  const chipStyles: Record<
    string,
    { backgroundColor: string; color: string }
  > = {
    pending: { backgroundColor: '#FFECB3', color: '#D57F17' },
    approved: { backgroundColor: '#DFF0D8', color: '#4CAF50' },
    denied: { backgroundColor: '#F8D7DA', color: '#F44336' },
    expired: { backgroundColor: '#C62828', color: 'white' },
  };
  const handleDeleteRow = async (row: any) => {
    await dispatch(
      deleteUserDetails({
        commonID,
        tenantID,
        app,
        username: row?.username,
        macAddress: row?.mac_address,
      }),
    );
    toast.success('User Deleted Successfully');
    handlePopoverClose();
  };

  const filteredRows = userDetails.filter((row) => {
    const rowString = JSON.stringify(row).toLowerCase();
    const formattedApprovalRequestTime = row.approval_request_time
      ? formatDate(Number(row.approval_request_time))
      : 'NA';
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const matchesSearchQuery = rowString.includes(lowerCaseSearchQuery);
    const matchesDate = formattedApprovalRequestTime
      .toLowerCase()
      .includes(lowerCaseSearchQuery);
    return matchesSearchQuery || matchesDate;
  });

  const paginatedRows = filteredRows.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  return (
    <Fade
      in={editScreen === 'DeviceManagement'}
      {...(editScreen === 'DeviceManagement' ? { timeout: 1000 } : {})}
    >
      <Box>
        <Box sx={{ marginTop: 2, display: 'flex', gap: 4 }}>
          <StyledTextField
            id="outlined-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={buttonIconStyles} />
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder="Search for device details"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ maxWidth: '25rem' }}
          />
          <Button
            size="small"
            startIcon={<CachedIcon sx={buttonIconStyles} />}
            onClick={() => {
              dispatch(getApplicationStatus());
              dispatch(updateEditPageName('DeviceManagement'));
            }}
            sx={{ marginBottom: 1 }}
          >
            Reload
          </Button>
        </Box>
        <TableContainer
          sx={{
            border: 'none',
            boxShadow: 'none',
            height: { xl: '58.5vh', lg: '50vh' },
            paddingTop: '2rem',
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnLabels.map((label) => (
                  <TableCell>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {userDetails.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <NoRecordsFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableBody>
              {paginatedRows.map((row) => (
                <TableRow
                  key={`${row.mac_address}-${row.username}`}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.device_details.ip}</TableCell>
                  <TableCell>{row.mac_address}</TableCell>
                  <TableCell>{row.device_details.os}</TableCell>
                  <TableCell>
                    {Number(row.approval_request_time) !== 0
                      ? formatDate(Number(row.approval_request_time))
                      : 'NA'}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        row.status.charAt(0).toUpperCase() + row.status.slice(1)
                      }
                      sx={{
                        ...chipStyles[row.status],
                        fontWeight: 'bold',
                        fontSize: '0.875rem',
                        width: '6.25rem',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Click for actions" placement="top">
                      <Button
                        sx={{
                          backgroundColor: '#3f51b5',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#303f9f',
                          },
                          borderRadius: '0.5rem',
                          padding: '0.5rem 1rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onMouseEnter={(event) => handlePopoverOpen(event, row)}
                        // onMouseLeave={handlePopoverClose}
                      >
                        Actions
                      </Button>
                    </Tooltip>

                    <Popover
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      sx={{
                        borderRadius: '0.5rem',
                        padding: '0.625rem',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          padding: '0.375rem',
                          width: '9.375rem',
                          backgroundColor: '#f9f9f9',
                          borderRadius: '0.375rem',
                          boxShadow: '0rem 0.188rem 0.5rem rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {selectedRowForPopover?.status === 'denied'
                        || selectedRowForPopover?.status === 'pending' ? (
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Button
                              onClick={() => handleOpen()}
                              sx={{
                                color: '#4caf50',
                                justifyContent: 'flex-start',
                                textTransform: 'none',
                                padding: '0.5rem 0.875rem',
                                fontSize: '0.938rem',
                                '&:hover': { backgroundColor: '#e8f5e9' },
                                fontWeight: 'bold',
                              }}
                            >
                              <CheckIcon
                                sx={{ marginRight: 1, fontSize: '1.125rem' }}
                              />
                              {' '}
                              Approve
                            </Button>
                            <Button
                              onClick={() => handleDeleteRow(selectedRowForPopover)}
                              sx={{
                                color: '#f44336',
                                justifyContent: 'flex-start',
                                textTransform: 'none',
                                padding: '0.5rem 0.875rem',
                                fontSize: '0.938rem',
                                '&:hover': { backgroundColor: '#ffebee' },
                                fontWeight: 'bold',
                              }}
                            >
                              <DeleteIcon
                                sx={{ marginRight: 1, fontSize: '1.125rem' }}
                              />
                              {' '}
                              Delete
                            </Button>
                          </Box>
                          ) : (
                            selectedRowForPopover?.status === 'approved' && (
                            <Box
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <Button
                                onClick={() => handleOpen()}
                                sx={{
                                  color: '#FF9800',
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                  padding: '0.5rem 0.875rem',
                                  fontSize: '0.938rem',
                                  '&:hover': { backgroundColor: '#ffebee' },
                                  fontWeight: 'bold',
                                }}
                              >
                                <BlockIcon
                                  sx={{ marginRight: 1, fontSize: '1.125rem' }}
                                />
                                {' '}
                                Revoke
                              </Button>
                              <Button
                                onClick={() => handleDeleteRow(selectedRowForPopover)}
                                sx={{
                                  color: '#f44336',
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                  padding: '0.5rem 0.875rem',
                                  fontSize: '0.938rem',
                                  '&:hover': { backgroundColor: '#ffebee' },
                                  fontWeight: 'bold',
                                }}
                              >
                                <DeleteIcon
                                  sx={{ marginRight: 1, fontSize: '1.125rem' }}
                                />
                                {' '}
                                Delete
                              </Button>
                            </Box>
                            )
                          )}
                      </Box>
                    </Popover>
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(filteredRows.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          size="small"
          sx={{
            marginTop: 2,
            paddingTop: 1,
            display: 'flex',
            justifyContent: 'left',
          }}
        />
        <PopupDialog
          open={open}
          onClose={handleClose}
          data={selectedRowForPopover}
          commonID={commonID}
          tenantID={tenantID}
          app={app}
        />
      </Box>
    </Fade>
  );
}

export default DeviceManagement;
