import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, FormControlLabel, Checkbox, Button,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { RootState, AppDispatch } from '../../Redux/store';
import { StyledTextField } from '../../styles/textField';
import { SmallHeading } from '../../styles/smallHeading';
import { SubLabel } from '../../styles/sublabel';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { mediumIconStyles } from '../../styles/icon';
import {
  addApproverEmail,
  updateOutOfNetworkMacValidation,
  updateDeviceBasedRestriction,
  removeApproverEmail,
  addWhitelistedMACaddress,
  removeWhitelistedMACaddress,
  updatewhitelistedMACaddressList,
  updatewhitelistedIPList,
  addwhitelistedIPaddress,
  removeWhitelistedIPaddresses,
  updateWhitelistedMACaddressToggle,
  updateApproverEmailList,
} from '../../Redux/Slices/Policy/policy';

function DeviceRestriction() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    enableDeviceRestriction,
    outOfNetworkMacValidation,
    whitelistedMACaddress,
    whitelistedIPaddress,
    whitelistedMACaddressToggle,
  } = useSelector((state: RootState) => state.policySettings);
  const approverEmail = useSelector((state: RootState) => {
    const emails = state.policySettings.approverEmail;
    return emails.length ? emails : [''];
  });

  return (
    <Box py="0.5rem">
      <SmallHeading>Device Based Restriction</SmallHeading>
      <Box pl="1rem" px="0.5rem">
        <Box
          pt="0.5rem"
          display="flex"
          alignItems="center"
          borderTop="0.063rem solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enableDeviceRestriction}
                onChange={() => (enableDeviceRestriction
                  ? dispatch(updateDeviceBasedRestriction(false))
                  : dispatch(updateDeviceBasedRestriction(true)))}
                name="device-restriction-checkbox"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                Enabling this will apply device based restrictions to users
                based on the configured settings and specified timings.
              </SubLabel>
            )}
          />
        </Box>
      </Box>
      {enableDeviceRestriction && (
        <Box
          display="flex"
          px={{
            xxl: '1rem',
            xl: '1rem',
            lg: '0.5rem',
            md: '0.5rem',
          }}
          pt="0.5rem"
          flexDirection="column"
        >
          <Box display="flex" gap="1rem">
            <ResponsiveTypography sx={{ fontSize: '0.9rem !important' }}>
              Approver Notification Email
            </ResponsiveTypography>
          </Box>
          <Box>
            <Button
              size="small"
              sx={{ fontSize: { xl: '0.813rem', lg: '0.625rem' } }}
              onClick={() => dispatch(addApproverEmail())}
              disabled={approverEmail.length >= 5}
            >
              + Add new approval email
            </Button>
          </Box>

          {approverEmail?.map((element, index) => (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                gap: { xl: '0.5rem', lg: '0.25rem' },
                padding: '0.25rem',
                width: '50%',
              }}
            >
              <StyledTextField
                value={element}
                onChange={(event: any) => dispatch(
                  updateApproverEmailList({
                    index,
                    value: event.target.value,
                  }),
                )}
              />
              <DeleteOutlineOutlinedIcon
                sx={{
                  ...mediumIconStyles,
                  cursor: approverEmail.length <= 1 ? 'not-allowed' : 'pointer',
                  opacity: approverEmail.length <= 1 ? 0.5 : 1,
                }}
                onClick={() => {
                  if (approverEmail.length > 1) {
                    dispatch(removeApproverEmail(index));
                  }
                }}
              />
            </Box>
          ))}
        </Box>
      )}
      {enableDeviceRestriction && (
        <Box display="flex" flexDirection="column" pt={1} px="1rem">
          <Box
            display="flex"
            gap="1rem"
            borderBottom="0.063rem solid lightgray"
          >
            <ResponsiveTypography sx={{ fontSize: '0.9rem !important' }}>
              Out of network MAC validation
            </ResponsiveTypography>
          </Box>
          <Box display="flex" justifyContent="spaceAround">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={outOfNetworkMacValidation}
                  color="primary"
                  onChange={() => (outOfNetworkMacValidation
                    ? dispatch(updateOutOfNetworkMacValidation(false))
                    : dispatch(updateOutOfNetworkMacValidation(true)))}
                />
              )}
              label={<SubLabel>Enable out-of-network MAC validation</SubLabel>}
            />
            {/* <Button
              size="small"
              sx={{ fontSize: { xl: '0.813rem', lg: '0.625rem' } }}
              onClick={() => {
                console.log('THE DATA IS --_> ');
              }}
            >
              + Import IP
            </Button> */}
          </Box>
          {outOfNetworkMacValidation && (
            <Box>
              <Box>
                <Button
                  size="small"
                  sx={{ fontSize: { xl: '0.813rem', lg: '0.625rem' } }}
                  onClick={() => dispatch(addwhitelistedIPaddress())}
                >
                  + Add whitelisted IP addresses
                </Button>
              </Box>
              <Box
                sx={{
                  maxHeight: '200px',
                  overflowY:
                    whitelistedIPaddress.length > 4 ? 'auto' : 'visible',
                  marginTop: '0.5rem',
                }}
              >
                {whitelistedIPaddress?.map((element, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      gap: { xl: '0.5rem', lg: '0.25rem' },
                      padding: '0.25rem',
                      width: '50%',
                    }}
                  >
                    <StyledTextField
                      value={element}
                      onChange={(event: any) => dispatch(
                        updatewhitelistedIPList({
                          index,
                          value: event.target.value,
                        }),
                      )}
                    />
                    <DeleteOutlineOutlinedIcon
                      sx={mediumIconStyles}
                      onClick={() => dispatch(removeWhitelistedIPaddresses(index))}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
      {enableDeviceRestriction && (
        <Box display="flex" flexDirection="column" pt={1} px="1rem">
          <Box
            display="flex"
            flexDirection="column"
            pt={1}
            sx={{ borderBottom: '0.063rem solid lightgray' }}
          >
            <ResponsiveTypography sx={{ fontSize: '0.9rem !important' }}>
              Whitelisted MAC address
            </ResponsiveTypography>
          </Box>
          <FormControlLabel
            control={(
              <Checkbox
                checked={whitelistedMACaddressToggle}
                color="primary"
                onChange={() => (whitelistedMACaddressToggle
                  ? dispatch(updateWhitelistedMACaddressToggle(false))
                  : dispatch(updateWhitelistedMACaddressToggle(true)))}
              />
            )}
            label={<SubLabel>Enable device-based restriction.</SubLabel>}
          />
          {/* </RadioGroup> */}
          <Box>
            {whitelistedMACaddressToggle && (
              <Box>
                <Button
                  size="small"
                  sx={{ fontSize: { xl: '0.813rem', lg: '0.625rem' } }}
                  onClick={() => dispatch(addWhitelistedMACaddress())}
                >
                  + Add whitelisted MAC addresses
                </Button>
              </Box>
            )}
            <Box
              sx={{
                maxHeight: '200px',
                overflowY:
                  whitelistedMACaddress.length > 4 ? 'auto' : 'visible',
                marginTop: '0.5rem',
              }}
            >
              {whitelistedMACaddressToggle
                && whitelistedMACaddress?.map((element, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      gap: { xl: '0.5rem', lg: '0.25rem' },
                      padding: '0.25rem',
                      width: '50%',
                    }}
                  >
                    <StyledTextField
                      value={element}
                      onChange={(event: any) => dispatch(
                        updatewhitelistedMACaddressList({
                          index,
                          value: event.target.value,
                        }),
                      )}
                    />
                    <DeleteOutlineOutlinedIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => dispatch(removeWhitelistedMACaddress(index))}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DeviceRestriction;
