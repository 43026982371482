import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import NoRecordsFound from '../NoRecordsFound';
import {
  hoverRowStyles,
  tableCellStyleRestrictions,
  tableRowCellStyleRestrictions,
  insightsTableCell,
  insightsTableRowCell,
  tableRowCellStyle,
  tableCellStyle,
} from './tableStyles';
import { CommonPagination } from '../Pagination/CommonPagination';
import { AppDispatch, RootState } from '../../Redux/store';
import { fetchRestrictionPerDay } from '../../Redux/Slices/Auditing/PersistState/preferencesHandler';
import {
  updateEmailAddresses,
  updatePage,
  updateSelectedDateButton,
  setFilterRows,
  updateFilterBox,
  updateEndTime,
  updateStartTime,
} from '../../Redux/Slices/Auditing/PersistState/preferences';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { resetState } from '../../pages/UserInsights/userInsights';

interface Column {
  field: string;
  headerName: string;
  align?: 'left' | 'right' | 'center';
}

interface AuditingCommonTableProps {
  data: any[];
  columns: Column[];
  pages: 'audit-dashboard' | 'insights';
}

function AuditingCommonTable({
  data = [],
  columns,
  pages,
}: AuditingCommonTableProps) {
  const page = useSelector((state: RootState) => state.preferences.page);
  const { showFilterBox } = useSelector(
    (state: RootState) => state.preferences,
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageToUpdate = newPage;
    dispatch(updatePage(pageToUpdate + 1));
    dispatch(fetchRestrictionPerDay());
  };

  const isEmptyRecords = data.length === 0;

  const renderFieldValue = (value: any) => {
    if (value === null || value === undefined || value === '') {
      return '-';
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value;
  };

  const getTruncatedStyle = (width: number) => ({
    display: 'inline-block',
    maxWidth: `${width}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  });

  const navigate = useNavigate();

  const handleRowClick = (row: any) => {
    resetState(dispatch);
    dispatch(updateSelectedDateButton(30));
    dispatch(updateFilterBox(true));
    const newValue = row.user;
    const now = dayjs();
    let selectedFromDate = dayjs();
    selectedFromDate = now.subtract(1, 'month');
    const formattedStartTime = selectedFromDate.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndTime = now.format('YYYY-MM-DD HH:mm:ss');

    const newFilterRow = {
      id: Date.now(),
      selectedField: 'user',
      chipList: [newValue],
      fromDate: formattedStartTime,
      toDate: formattedStartTime,
    };
    dispatch(updateStartTime(formattedStartTime));
    dispatch(updateEndTime(formattedEndTime));
    dispatch(updateEmailAddresses([newValue]));
    dispatch(setFilterRows([newFilterRow]));
    dispatch(fetchRestrictionPerDay());
    navigate('/insights');
  };

  const { cellStyle, rowCellStyle } = React.useMemo(() => {
    switch (pages) {
      case 'audit-dashboard':
        return {
          cellStyle: tableCellStyleRestrictions,
          rowCellStyle: tableRowCellStyleRestrictions,
        };
      case 'insights':
        return {
          cellStyle: insightsTableCell,
          rowCellStyle: insightsTableRowCell,
        };
      default:
        return { cellStyle: tableCellStyle, rowCellStyle: tableRowCellStyle };
    }
  }, [pages]);

  const width = pages === 'insights' ? 440 : 170;

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ffffff',
      color: '#333333',
      maxWidth: 300,
      fontSize: '14px',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      padding: '10px 15px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#ffffff',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
  }));

  return (
    <Box>
      <Box
        sx={{
          height: !showFilterBox ? '640px' : '366px',
          overflowY: 'auto',
          borderBottom: '3px solid lightgray',
        }}
      >
        <Table sx={{ width: '100%', borderCollapse: 'collapse' }}>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field} sx={cellStyle}>
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmptyRecords ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Box
                    sx={{
                      '& img': {
                        height: {
                          xxl: '20vh !important',
                          xl: '17vh !important',
                        },
                      },
                    }}
                  >
                    <NoRecordsFound />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              Array.isArray(data)
              && data.map((row: any, rowIndex: number) => (
                <TableRow
                  key={`${rowIndex + 1}-${row[columns[0].field]}`}
                  onClick={
                    pages === 'audit-dashboard'
                      ? () => handleRowClick(row)
                      : undefined
                  }
                  sx={{
                    ...hoverRowStyles,
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    cursor: 'pointer !important',
                  }}
                >
                  {columns.map((column) => (
                    <TableCell key={column.field} sx={rowCellStyle}>
                      {pages === 'audit-dashboard' ? (
                        <HtmlTooltip
                          title={
                            Array.isArray(row[column.field]) ? (
                              <Box>
                                <Typography
                                  color="inherit"
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: '#1976d2',
                                    borderBottom: '1px solid #e0e0e0',
                                    marginBottom: '8px',
                                    paddingBottom: '4px',
                                    textAlign: 'center',
                                    letterSpacing: '0.5px',
                                  }}
                                >
                                  {column.headerName || column.field}
                                </Typography>
                                <Box
                                  sx={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    paddingRight: '4px',
                                  }}
                                >
                                  <ul
                                    style={{
                                      margin: 0,
                                      paddingLeft: '20px',
                                      color: '#444444',
                                      fontSize: '15px',
                                    }}
                                  >
                                    {row[column.field].map((item: string) => (
                                      <li
                                        key={`${row[column.field]}`}
                                        style={{
                                          marginBottom: '8px',
                                          lineHeight: '1.6',
                                          color: '#2c3e50',
                                          textAlign: 'justify',
                                          fontWeight: '500',
                                          letterSpacing: '0.4px',
                                          fontStyle: 'italic',
                                        }}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <ResponsiveTypography
                                  color="inherit"
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    color: '#1976d2',
                                    borderBottom: '1px solid #e0e0e0',
                                    marginBottom: '8px',
                                    paddingBottom: '4px',
                                    letterSpacing: '0.5px',
                                  }}
                                >
                                  {column.headerName || column.field}
                                </ResponsiveTypography>
                                <Box
                                  sx={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    paddingRight: '4px',
                                  }}
                                >
                                  <ResponsiveTypography
                                    sx={{
                                      fontSize: '15px',
                                      color: '#444444',
                                      fontWeight: '400',
                                    }}
                                  >
                                    {renderFieldValue(row[column.field])}
                                  </ResponsiveTypography>
                                </Box>
                              </Box>
                            )
                          }
                        >
                          <span style={getTruncatedStyle(width)}>
                            {renderFieldValue(row[column.field])}
                          </span>
                        </HtmlTooltip>
                      ) : (
                        <span
                          style={getTruncatedStyle(
                            pages === 'insights' ? 200 : 170,
                          )}
                        >
                          {renderFieldValue(row[column.field])}
                        </span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Box>
      {pages !== 'audit-dashboard' && (
        <Box>
          <CommonPagination
            pages={pages}
            page={page}
            handlePageChange={handleChangePage}
            data={data}
          />
        </Box>
      )}
    </Box>
  );
}

export default AuditingCommonTable;
