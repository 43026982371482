import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubLabel = styled(Typography)(({ theme }: any) => ({
  fontWeight: 400,
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '0.9rem',
  },
}));
