import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getCookie, isValidIPAddress } from '../../../utils/utility';
import { updateBasicSettings } from './BasicSettings/updateApplicationHandler';
import { resetPolicyDetails } from '../Policy/policy';
import { getApplicationStatus } from './applicationHandler';

interface InitialState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  app: string;
  step: string;
  authenticationSource: any[];
  authenticationID: string | boolean;
  idpMetadata: any[];
  isConfigured: boolean;
  application: any[];
  configuredGroups: any[];
  editScreen: string;
  sessionDetails: any;
  editPageName: string;
}

const initialState: InitialState = {
  status: 'idle',
  app: '',
  step:
    process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN
    || 'AuthenticationScreen',
  authenticationSource: [],
  authenticationID: '',
  idpMetadata: [],
  isConfigured: false,
  application: [],
  configuredGroups: [],
  editScreen: 'BasicSettings',
  sessionDetails: [],
  editPageName: 'BasicSettings',
};

export const getAllGroups = createAsyncThunk(
  'getAllGroups',
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const token = await getCookie('token');
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-groups`,
        {
          app: state.application.app,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      toast.error('Error fetching configured groups.');
      return thunkAPI.rejectWithValue('Error fetching configured groups.');
    }
  },
);

export const configureNewPolicy = createAsyncThunk(
  'configureNewPolicy',
  async (_, thunkAPI) => {
    var step = process.env.REACT_APP_POLICY_SETTINGS_SCREEN;
    var hasInvalidIP = false;
    const token = await getCookie('token');
    const state: any = thunkAPI.getState();
    if (!state.policySettings.identifier || !state.policySettings.description) {
      toast.error('Please add necessary details.');
    } else if (state.policySettings?.enableIPRestriction) {
      hasInvalidIP = state.policySettings?.ipAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          toast.error('Invalid IP Address');
          return true;
        }
        return false;
      });
    }
    if (hasInvalidIP) {
      return step;
    }
    await axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        {
          policy_details: state.policySettings,
          type: 'save',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(async () => {
        await thunkAPI.dispatch(resetPolicyDetails());
        toast.success('Policy has been Configured.');
        step = process.env.REACT_APP_GROUP_SETTINGS_SCREEN;
      })
      .catch((error) => {
        if (error) {
          toast.error('Unable to create policy.');
        }
      });
    return step;
  },
);

const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateApplication: (state, action: PayloadAction<string>) => {
      state.app = action.payload;
    },
    updateIDPMetadata: (state, action: PayloadAction<any>) => {
      state.idpMetadata = action.payload;
    },
    updateApplicationAuthenticationID: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.authenticationID = action.payload;
    },
    previousScreen: (state) => {
      state.step = state.step === (process.env.REACT_APP_BASIC_SETTINGS_SCREEN ?? '')
        ? process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN ?? ''
        : state.step === (process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? '')
          ? process.env.REACT_APP_BASIC_SETTINGS_SCREEN ?? ''
          : state.step === (process.env.REACT_APP_GROUP_SETTINGS_SCREEN ?? '')
            ? process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? ''
            : state.step;
    },
    nextScreen: (state) => {
      state.step = state.step
        === (process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN ?? '')
        ? process.env.REACT_APP_BASIC_SETTINGS_SCREEN ?? ''
        : state.step === (process.env.REACT_APP_BASIC_SETTINGS_SCREEN ?? '')
          ? process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? ''
          : state.step === (process.env.REACT_APP_POLICY_SETTINGS_SCREEN ?? '')
            ? process.env.REACT_APP_GROUP_SETTINGS_SCREEN ?? ''
            : state.step;
    },
    updateEditScreen: (state, action: PayloadAction<string>) => {
      state.editScreen = action.payload;
    },
    changeScreen: (state, action: PayloadAction<string>) => {
      state.step = action.payload;
    },
    updateEditPageName: (state, action: PayloadAction<string>) => {
      state.editPageName = action.payload;
    },
    redirectToEditScreen: (state, action: PayloadAction<boolean>) => {
      state.isConfigured = action.payload;
    },
    updateConfiguredGroups: (state, action: PayloadAction<any>) => {
      state.configuredGroups = action.payload;
    },
    setSessionDetails: (state, action: PayloadAction<any>) => {
      state.sessionDetails = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getApplicationStatus.pending, (state) => {
        state.isConfigured = false;
        state.status = 'loading';
      })
      .addCase(getApplicationStatus.fulfilled, (state, action: any) => {
        state.status = 'succeeded';
        state.authenticationID = action.payload?.success?.authentication_id;
        state.application = action.payload;
        state.idpMetadata = action.payload?.success?.authentication_saml_idp_metadata;
        if (
          !action.payload?.success?.authentication_id
          && !action.payload?.success?.identifier
          && !action.payload?.success?.organization_domain
        ) {
          state.step = process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN ?? '';
        } else if (
          action.payload?.success?.identifier
          && action.payload?.success?.organization_domain
        ) {
          state.isConfigured = true;
        } else if (action.payload?.success?.authentication_id) {
          state.step = process.env.REACT_APP_BASIC_SETTINGS_SCREEN ?? '';
        }
      })
      .addCase(updateBasicSettings.fulfilled, (state, action) => {
        state.step = action.payload ?? '';
      })
      .addCase(configureNewPolicy.fulfilled, (state, action) => {
        state.step = action.payload ?? '';
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.configuredGroups = action.payload.GroupData;
      });
  },
});

export const {
  updateApplication,
  updateApplicationAuthenticationID,
  previousScreen,
  nextScreen,
  updateConfiguredGroups,
  updateEditScreen,
  changeScreen,
  updateIDPMetadata,
  redirectToEditScreen,
  setSessionDetails,
  updateEditPageName,
} = appSlice.actions;
export default appSlice;
