import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Autocomplete,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateLocationList,
  updateLocationToggle,
  updateLocationBasedrestriction,
  removeIP,
  addIPAddress,
  updateIPToggle,
  updateIPList,
  updateEnableIPRestriction,
} from '../../Redux/Slices/Policy/policy';
import { StyledTextField } from '../../styles/textField';
import { SmallHeading } from '../../styles/smallHeading';
import { SubLabel } from '../../styles/sublabel';
import { countriesArray } from '../../data/countries';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { mediumIconStyles } from '../../styles/icon';

function NetworkSettings() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    enableIPRestriction,
    IPAddressToggle,
    ipAddresses,
    enableLocationBasedRestriction,
    locationRestrictionToggle,
    location,
  } = useSelector((state: RootState) => state.policySettings);
  return (
    <Box>
      <SmallHeading>Network Based Restriction</SmallHeading>
      <Box
        id="ip-restriction"
        display="flex"
        px={{
          xxl: '1rem',
          xl: '1rem',
          lg: '0.5rem',
          md: '0.5rem',
        }}
        pt="0.5rem"
        flexDirection="column"
      >
        <Box display="flex" gap="1rem">
          <ResponsiveTypography>IP Configuration</ResponsiveTypography>
        </Box>
        <Box
          py="0.025rem"
          display="flex"
          flexDirection="column"
          borderTop="0.063rem solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enableIPRestriction}
                onChange={() => (enableIPRestriction
                  ? dispatch(updateEnableIPRestriction(false))
                  : dispatch(updateEnableIPRestriction(true)))}
                name="ip-restriction-checkbox"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                By enabling this IP Restriction will be applied to users based
                on the list provided.
              </SubLabel>
            )}
          />
          {enableIPRestriction && (
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2rem"
              >
                <SubLabel>
                  Action if IP address matches the given list:
                </SubLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="IPAddressToggle"
                  value={IPAddressToggle}
                  onChange={(event: any) => dispatch(updateIPToggle(event?.target.value))}
                >
                  <FormControlLabel
                    value="allow"
                    control={<Radio size="small" />}
                    label={<SubLabel>Allow</SubLabel>}
                    sx={{ fontSize: '0.813rem' }}
                  />
                  <FormControlLabel
                    value="deny"
                    control={<Radio size="small" />}
                    label={<SubLabel>Deny</SubLabel>}
                    sx={{ fontSize: '0.938rem' }}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Button
                  size="small"
                  sx={{ fontSize: { xl: '0.813rem', lg: '0.625rem' } }}
                  onClick={() => dispatch(addIPAddress())}
                >
                  + Add IP Address
                </Button>
              </Box>
              {ipAddresses.map((element, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    gap: { xl: '0.5rem', lg: '0.25rem' },
                    padding: '0.25rem',
                    width: '50%',
                  }}
                >
                  <StyledTextField
                    value={element}
                    onChange={(event: any) => dispatch(
                      updateIPList({ index, value: event.target.value }),
                    )}
                  />
                  <DeleteOutlineOutlinedIcon
                    sx={mediumIconStyles}
                    onClick={() => dispatch(removeIP(index))}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        id="location-based-restriction"
        display="flex"
        px={{
          xxl: '1rem',
          xl: '1rem',
          lg: '0.5rem',
          md: '0.5rem',
        }}
        py="1rem"
        flexDirection="column"
      >
        <Box display="flex" gap="1rem" pb="0.5rem">
          <ResponsiveTypography>Location Configuration</ResponsiveTypography>
        </Box>
        <Box
          pt="0.5rem"
          display="flex"
          flexDirection="column"
          borderTop="0.063rem solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enableLocationBasedRestriction}
                onChange={() => (enableLocationBasedRestriction
                  ? dispatch(updateLocationBasedrestriction(false))
                  : dispatch(updateLocationBasedrestriction(true)))}
                name="prevent-download-checkbox"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                By enabling this feature, you are restricting access to users
                based on their Location
              </SubLabel>
            )}
          />
          {enableLocationBasedRestriction && (
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="2rem"
                alignItems="center"
              >
                <SubLabel>
                  Allow or deny action based on provided location:
                </SubLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="locationRestrictionToggle"
                  value={locationRestrictionToggle}
                  onChange={(event: any) => dispatch(updateLocationToggle(event?.target.value))}
                >
                  <FormControlLabel
                    value="allow"
                    control={<Radio />}
                    label={<SubLabel>Allow</SubLabel>}
                    sx={{ fontSize: '0.813rem' }}
                  />
                  <FormControlLabel
                    value="deny"
                    control={<Radio />}
                    label={<SubLabel>Deny</SubLabel>}
                    sx={{ fontSize: '0.938rem' }}
                  />
                </RadioGroup>
              </Box>
              <Autocomplete
                disablePortal
                fullWidth
                id="timezone"
                size="small"
                multiple
                options={countriesArray}
                renderInput={(params: any) => (
                  <StyledTextField {...params} label="Select Location" />
                )}
                value={location}
                onChange={(event: any, values: any) => {
                  dispatch(updateLocationList(values));
                }}
                sx={{
                  display: 'inline-block',
                  '& input': {
                    width: '12.5rem',
                    bgcolor: 'background.paper',
                    color: (theme) => theme.palette.getContrastText(
                      theme.palette.background.paper,
                    ),
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default NetworkSettings;
