export const lightBackgroundButtonStyles = {
  width: 'auto',
  padding: {
    md: '0.25rem 0.625rem !important',
    lg: '0.25rem 0.313rem !important',
    xl: '0.313rem 0.438rem !important',
    xxl: '0.313rem 0.438rem !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '0.5rem', lg: '0.438rem', md: '0.313rem' },
  backgroundColor: 'white',
  color: '#2D3748',
  border: {
    xl: '0.094rem solid #2D3748',
    lg: '0.063rem solid #2D3748',
    md: '0.063rem solid #2D3748',
  },
  '&:hover': {
    backgroundColor: 'white',
    color: '#2D3748',
    border: {
      xl: '0.094 solid #2D3748',
      lg: '0.063rem solid #2D3748',
      md: '0.063rem solid #2D3748',
    },
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
    border: 'none',
  },
};
export const lightBlueBackgroundButtonStyles = {
  width: 'auto',
  padding: {
    md: '0.125rem 0.188rem !important',
    lg: '0.25rem 0.313rem !important',
    xl: '0.313rem 0.438rem !important',
    xxl: '0.313rem 0.438rem !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '0.5rem', lg: '0.438rem', md: '0.313rem' },
  backgroundColor: 'white',
  color: '#0065FF',
  border: {
    xl: '0.094rem solid #0065FF',
    lg: '0.063rem solid #0065FF',
    md: '0.063rem solid #0065FF',
  },
  '&:hover': {
    backgroundColor: 'white',
    color: '#0065FF',
    border: {
      xl: '0.094rem solid #0065FF',
      lg: '0.063rem solid #0065FF',
      md: '0.063rem solid #0065FF',
    },
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
    border: 'none',
  },
};
export const navyBlueBackgroundButtonStyle = {
  width: 'auto',
  padding: {
    md: '0.125rem 0.188rem !important',
    lg: '0.188rem 0.313rem !important',
    xl: '0.313rem 0.438rem !important',
    xxl: '0.313rem 0.438rem !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '0.5rem', lg: '0.438rem', md: '0.313rem' },
  backgroundColor: '#2D3748',
  color: 'white',
  border: 'none',
  '&:hover': {
    backgroundColor: '#2D3748',
    color: 'white',
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};

export const cyanBlueBackgroundButtonStyles = {
  width: 'auto',
  padding: {
    md: '0.25rem 0.625rem !important',
    lg: '0.188rem 0.313rem !important',
    xl: '0.313rem 0.438rem !important',
    xxl: '0.313rem 0.438rem !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '0.5rem', lg: '0.438rem', md: '0.313rem' },
  backgroundColor: '#0052CC',
  color: 'white',
  border: 'none !important',
  '&:hover': {
    backgroundColor: '#0052CC',
    color: 'white',
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};

export const zeroPaddingAutoWidthButton = {
  margin: '0 !important',
  padding: '0 !important',
  width: 'auto !important',
  minWidth: 'auto !important',
};

export const cyanBlueBackgroundButtonStyles1 = {
  width: 'auto',
  padding: {
    md: '0.125rem 0.188rem !important',
    lg: '0.188rem 0.313rem !important',
    xl: '0.313rem 0.438rem !important',
    xxl: '0.125rem 0.25rem !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.8rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '0.5rem', lg: '0.438rem', md: '0.313rem' },
  backgroundColor: '#0052CC',
  color: 'white',
  border: 'none !important',
  '&:hover': {
    backgroundColor: '#0052CC',
    color: 'white',
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};
const baseButtonStyles = {
  width: 'auto',
  padding: {
    md: '0.125rem 0.188rem !important',
    lg: '0.188rem 0.313rem !important',
    xl: '0.313rem 0.438rem !important',
    xxl: '0.313rem 0.438rem !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '0.5rem', lg: '0.438rem', md: '50.313rem' },
  color: 'white',
  border: 'none !important',
  '&:hover': {
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};

export const approveButtonStyles = {
  ...baseButtonStyles,
  backgroundColor: '#28a745',
  '&:hover': {
    backgroundColor: '#218838',
    color: 'white',
    border: 'none',
  },
};

export const revokeButtonStyles = {
  ...baseButtonStyles,
  backgroundColor: '#fd5c63',
  '&:hover': {
    backgroundColor: '#c82333',
    color: 'white',
    border: 'none',
  },
};
