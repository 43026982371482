export const tableCellStyle = {
  borderBottom: '3px solid lightgray',
  padding: 0.8,
  fontSize: { xl: '0.8rem', md: '0.6rem' },
  color: '#6B778C',
  cursor: 'default',
  backgroundColor: 'red',
};

export const basicTableStyles = {
  borderBottom: 'none',
};

export const tableRowCellStyle = {
  borderBottom: 'none',
  padding: 0.7,
  color: '#172B4D',
  fontSize: { xl: '0.8rem', md: '0.7rem' },
  backgroundColor: 'red',
};

export const tableCellStyleRestrictions = {
  borderBottom: '3px solid lightgray',
  padding: { xxl: '12px 12px', xl: '6px 6px' },
  fontSize: { xxl: '1rem', xl: '0.9rem' },
  color: '#6B778C',
  cursor: 'default',
  textAlign: 'left',
};

export const tableRowCellStyleRestrictions = {
  borderBottom: 'none',
  padding: { xxl: '12px 12px', xl: '8px 8px' },
  color: '#172B4D',
  fontSize: { xxl: '1rem', xl: '0.9rem' },
  // cursor: 'pointer',
  textAlign: 'left',
  minWidth: '170px',
};

export const insightsTableCell = {
  borderBottom: '3px solid lightgray',
  fontSize: '1rem',
  color: '#6B778C',
  cursor: 'default',
};

export const insightsTableRowCell = {
  borderBottom: 'none',
  // padding: '12px 16px',
  color: '#172B4D',
  fontSize: '1rem',
  cursor: 'default',
};

export const tableCellStyleAuditing = {
  borderBottom: '3px solid lightgray',
  padding: {
    xxl: 1.0,
    xl2: 0.8,
    xl1: 0.5,
    xl: 0.1,
    lg1: 0.7,
    lg: '0rem !important',
  },
  fontSize: {
    xxl: '0.8rem',
    xl2: '0.8rem',
    xl1: '0.7rem',
    xl: '0.7rem',
    md: '0.6rem',
    lg1: '0.6rem',
    lg: '0.5rem',
  },
  color: '#6B778C',
  cursor: 'default',
  textAlign: 'center',
};

export const tableRowCellStyleAuditing = {
  borderBottom: 'none',
  // padding: 0.7,
  // paddingX: {xxl : '0.7 !important' , xl2 : '0.7 !important', xl1 : '0.6 !important'},
  padding: {
    xxl: '0.7 !important',
    xl2: 0.7,
    xl1: 0.2,
    xl: 0.1,
    lg1: '0rem !important',
    lg: '0rem !important',
  },
  // padding: {xxl : 0.7 , xl2 : '0.7 !important', xl1 : '0.6 !important'},
  marginLeft: '15px',
  // paddingTop
  color: '#172B4D',
  // paddingY : {xl1 : "0.0rem !important" , xxl : '0.7 !important' , xl2 : '0.7 !important'},
  // fontSize: { xl: '0.8rem', md: '0.7rem' },
  fontSize: {
    xxl: '0.9rem',
    xl2: '0.8rem',
    xl1: '0.7rem',
    xl: '0.65rem',
    md: '0.7rem',
    lg1: '0.6rem',
    lg: '0.6rem',
  },
  // border : '1px solid red'
  cursor: 'pointer',
};

export const hoverRowStyles = {
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};
