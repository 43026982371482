import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getCookie } from '../../../../utils/utility';

export const fetchRestrictionPerDay = createAsyncThunk(
  'restrictions/fetchRestrictionPerDay',
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const token = await getCookie('token');
      const apiType = state.preferences?.apiType || 'fields';

      const payload = {
        time: {
          start_time: state.preferences?.startTime || '2011-01-01 00:00:00',
          end_time: state.preferences?.endTime || '2025-12-31 23:59:59',
        },
        email: state.preferences?.emailAddresses || undefined,
        status: state.preferences?.statusArray || undefined,
        ip: state.preferences?.ipAddresses || undefined,
        fields: state.preferences?.fields || undefined,
        appAccessed: state.preferences?.appAccessed || undefined,
        group_by: ['status', 'appAccessed', 'casbApp'],
        casbApp: state.preferences?.casbApp || undefined,
        groupId: state.preferences?.groupId || undefined,
        macAddress: state.preferences?.macAddresses || undefined,
        limit: state.preferences?.limit || undefined,
        page: state.preferences?.page || undefined,
        query_type: apiType === 'count' ? 'count' : 'fields',
      };

      const filteredPayload = JSON.parse(
        JSON.stringify(payload, (key, value) => {
          if (value == null || (Array.isArray(value) && value.length === 0)) {
            return undefined;
          }
          return value;
        }),
      );
      const url = `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-restriction-data`;
      const response = await axios.post(url, filteredPayload, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });

      return response.data.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error);
        return thunkAPI.rejectWithValue(err.response.data.error);
      }
      toast.error('Failed to fetch restriction data.');
      throw err;
    }
  },
);
